/**
 * พิมพ์สัญญาขายรถ CarOk
 * **/

import React, { Component } from 'react'
import axios from 'axios'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Loading from '../Loading'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import * as moment from 'moment'
import { DateUtil } from '../../utils/dateUtil'
import {APIURL, PORT} from '../../config/config'
import ThaiBaht from 'thai-baht-text';
import NumberFormat from 'react-number-format'
import {AlertSuccess, AlertWarning} from '../Alert/Alert'
import { NumberUtil } from '../../utils/number-util'
import DatePickerIcon from "../DatePickerIcon";
import {getDocTypeConfig} from "../../services/companysetting";
import stringUtil from "../../utils/stringUtil";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew-Regular.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

const port = Number(window.env.PORT);

class CarSalesContractCarOk extends Component {
  constructor (props) {
    super(props)
    this.state = {
      contract_id: null,
      contract_no: '',
      write_at: '',
      // date
      startDateContract: new Date(),
      isOpenDateContract: false,
      contract_date_thai: null,
      contract_date: null,
      // end date
      seller_name: '', // ผู้ขาย
      seller_address: '', // ที่อยู่ผู้ขาย
      car_name: '',
      car_license_plate: '',
      car_num_engines: '',
      car_number: '', // เลขตัวรถ
      car_year_name: '', // ปี
      car_color_name: '', // สี
      // ขายให้แก่
      buyer_name: '', // สี
      buyer_age: '',
      buyer_address: '',
      buyer_mobile: '',
      card_type: 'บัตรประชาชน',
      card_code: '',
      card_out_at: '',
      real_sell_price: 0,
      cash_pledge: 0,
      // date
      startPaidDueDate: new Date(),
      isOpenPaidDueDate: false,
      paid_due_date_thai: null,
      paid_due_date: null,
      // end date
      isExist: false,
      car_id: '',
      customer_id: '',
      car_sell_id: '',
      province_name : '',
      note : '',
      is_vat: 0,
      vat: 0,
      vat_amount: 0,
      total_amount: 0,
      company_setting: null,
      is_cal_vat: 0,
      carBase64Img: '',

      startTranferDueDate: new Date(),
      isOpenTranferDueDate: false,
      tranfer_due_date_thai: null,
      tranfer_due_date: null,

      tranfer_by: '',
    }

    this.handleDateContract = this.handleDateContract.bind(this);
    this.loadCompanySetting = this.loadCompanySetting.bind(this);
    this.loadCarSellById = this.loadCarSellById.bind(this);
    this.loadCarContract = this.loadCarContract.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.loadDataCarSellPay = this.loadDataCarSellPay.bind(this);
    this.calOutstanding = this.calOutstanding.bind(this);
    this.handlePaidDueDate = this.handlePaidDueDate.bind(this);
    this.saveCarContract = this.saveCarContract.bind(this);
    this.printCarContract = this.printCarContract.bind(this);
   // this.loadCompanyLogoBase64 = this.loadCompanyLogoBase64.bind(this);
    this.handleTranferDueDate = this.handleTranferDueDate.bind(this);
    this.onTranferByChanged = this.onTranferByChanged.bind(this);
  }

  async componentDidMount () {
    this.mounted = true;
    const queryString = require('query-string');
    let car_sell = queryString.parse(this.props.location.search);
    let car_sell_id = car_sell.car_sell_id;

    await this.loadCompanySetting();
    await this.loadCarContract(car_sell_id);
    await this.loadCarSellById(car_sell_id);

    // await this.loadCompanyLogoBase64();


  }

  // async loadCompanyLogoBase64() {
  //   let uri =  APIURL + '/company_setting/logoimage/1';
  //   await axios.get(uri)
  //       .then(res => {
  //         let body = res.data.body
  //         this.setState({ carBase64Img: body })
  //       }).catch(error => {
  //         console.log('error:', error)
  //       })
  // }

  async loadCompanySetting () {
    await axios.get(APIURL + '/company_setting/1')
        .then(res => {
          let data = res.data
          let company_setting = data.company_setting
          if (company_setting !== null) {
            const is_vat = company_setting.is_vat
            // let vatPercent = this.state.company_setting.vat/100.0
            const doc_type = is_vat === 1 ? 'RT' : 'RE'
            const doc_config = getDocTypeConfig(company_setting, doc_type)
            const include_vat = is_vat === 1 ? 1 : 0

            this.setState({
              company_setting: company_setting,
              doc_type: doc_type,
              doc_config: doc_config,
              is_vat: is_vat,
              include_vat: include_vat,
            })
          }
        })
  }

  componentWillUnmount() {
    this.mounted = false
    // window.removeEventListener('resize', this.updateWindowDimensions);
  }


  // โหลดข้อมูลหลังจากบันทึกสัญญาแล้ว
  async loadCarContract (car_sell_id) {
    await axios.get(APIURL + '/contract/car_sell/' + car_sell_id)
      .then(res => {
        let data = res.data
        if(data.length === 1){
          let contract = data[0];
          // let contract_id = contract.contract_id
          // contract_id: contract_id
          let contract_date =  moment(contract.contract_date).format('YYYY-MM-DD')
          let contract_date_thai = DateUtil.convertLongDateThai(contract_date)

          let paid_due_date =  moment(contract.paid_due_date).format('YYYY-MM-DD')
          let paid_due_date_thai = DateUtil.convertLongDateThai(paid_due_date)

          let province_name = contract.car_license_plate_new !== '' &&  contract.car_license_plate_new !==  contract.car_license_plate_old ? contract.province_name_new : contract.province_name_old

          let is_cal_vat = contract.vat_amount === 0 ? 1 : 0

          let tranfer_due_date =  moment(contract.tranfer_due_date).format('YYYY-MM-DD')
          let tranfer_due_date_thai = DateUtil.convertLongDateThai(tranfer_due_date)

          this.setState({
            contract_date_thai: contract_date_thai,
            paid_due_date_thai: paid_due_date_thai,

            contract_id : contract.contract_id,
            contract_no : contract.contract_no,
            contract_date : contract.contract_date,
            contract_type : contract.contract_type,
            buyer_address : contract.buyer_address,
            buyer_age : contract.buyer_age,
            buyer_mobile : contract.buyer_mobile,
            buyer_name : contract.buyer_name,
            car_color_name : contract.car_color_name,
            car_id : contract.car_id,
            car_license_plate : contract.car_license_plate,
            car_name : contract.car_name,
            car_num_engines : contract.car_num_engines,
            car_number : contract.car_number,
            car_sell_id : contract.car_sell_id,
            car_year_name : contract.car_year_name,
            card_code : contract.card_code,
            card_out_at : contract.card_out_at,
            card_type : contract.card_type,
            cash_pledge : contract.cash_pledge,
            customer_id : contract.customer_id,
            paid_due_date : contract.paid_due_date,
            //
            real_sell_price : contract.real_sell_price,
            vat : contract.vat,
            vat_amount: contract.vat_amount,
            total_amount: contract.total_amount,
            //
            seller_name : contract.seller_name,
            seller_address : contract.seller_address,
            write_at : contract.write_at,
            note : contract.note,
            province_name : province_name,
            is_cal_vat: is_cal_vat,
            tranfer_due_date : contract.tranfer_due_date,
            tranfer_due_date_thai : tranfer_due_date_thai,
            tranfer_by : contract.tranfer_by
          })
        }
      })
  }

  async loadCarSellById (car_sell_id) {
    if (this.state.contract_id !== null) {
      // มีรายการสัญญาแล้ว ไม่ต้องโหลดข้อมูล
      return
    }

    const { is_vat, vat, company_setting } = this.state

    //โหลดข้อมูลตั้งต้น ตอนยังไม่กดบันทึกสัญญา
    await axios.get(APIURL + '/car_sell/' + car_sell_id)
      .then(res => {
        if(this.mounted) {
          let car_sell = res.data[0]
          // let car_sell_date = car_sell.car_sell_date
          // let date = new Date(car_sell_date)

          let car_name = car_sell.car_name
          let car_license_plate = car_sell.car_license_plate_new !== '' &&  car_sell.car_license_plate_new !==  car_sell.car_license_plate_old ? car_sell.car_license_plate_new : car_sell.car_license_plate_old
          let car_num_engines = car_sell.car_num_engines// เลขเครื่องยนต์
          car_num_engines = car_num_engines.toUpperCase()
          let car_number = car_sell.car_number // เลขตัวรถ
          car_number = car_number.toUpperCase()
          let car_year_name = car_sell.car_year_name // ปี
          let car_color_name = car_sell.car_color_name // สี

          let customer_lastname = car_sell.customer_lastname
          let buyer_name = car_sell.customer_name +' ' + customer_lastname // ผู้ซื้อ
          let buyer_age = car_sell.customer_age // ผู้ซื้อ
          let buyer_address = car_sell.customer_address // ผู้ซื้อ
          let buyer_mobile = car_sell.customer_mobile // ผู้ซื้อ
          let card_code = car_sell.customer_card_id // ผู้ซื้อ

          let real_sell_price = car_sell.real_sell_price
          let car_id = car_sell.car_id
          let customer_id = car_sell.customer_id

          let vat_amount = 0
          let is_cal_vat = 0


          if (is_vat === 1) {
            let vat_amount_cal = real_sell_price * (vat/100);
            vat_amount = parseFloat(String(vat_amount_cal)).toFixed(0);
          }


          let total_amount = Number(real_sell_price) + Number(vat_amount)

          let province_name = car_sell.car_license_plate_new !== '' &&  car_sell.car_license_plate_new !==  car_sell.car_license_plate_old ? car_sell.province_name_new : car_sell.province_name_old

          let write_at = company_setting.company_name
          let seller_name = company_setting.company_name
          let seller_address = company_setting.company_address
          let note = 'อากรแสตมป์ในการซื้อขายจะติดเมื่อไปทำการโอนกรรมสิทธิ์ครั้งเดียว'

          this.setState({
            car_name,
            car_license_plate,
            car_num_engines,
            car_number,
            car_year_name,
            car_color_name,
            buyer_name,
            buyer_age,
            buyer_address,
            buyer_mobile,
            card_code,
            real_sell_price,
            car_id,
            customer_id,
            province_name,
            car_sell_id,
            vat_amount,
            total_amount,
            is_cal_vat: is_cal_vat,
            seller_name: seller_name,
            seller_address: seller_address,
            note: note,
            write_at: write_at
          }, () => {
            this.loadDataCarSellPay(car_sell, car_id, customer_id)
          })
        }
      }).catch(error => {
        console.log ('error:', error)
      })
  }

  handleDateContract (date) {
    let contract_date =  moment(date).format('YYYY-MM-DD')
    let contract_date_thai = DateUtil.convertLongDateThai(contract_date)

    this.setState({
      startDateContract: date,
      isOpenDateContract: false,
      contract_date: contract_date,
      contract_date_thai: contract_date_thai
    })
  }

  handlePaidDueDate (date) {
    let paid_due_date =  moment(date).format('YYYY-MM-DD')
    let paid_due_date_thai = DateUtil.convertLongDateThai(paid_due_date)

    this.setState({
      startPaidDueDate: date,
      isOpenPaidDueDate: false,
      paid_due_date: paid_due_date,
      paid_due_date_thai: paid_due_date_thai
    })
  }

  handleTranferDueDate (date) {
    let tranfer_due_date =  moment(date).format('YYYY-MM-DD')
    let tranfer_due_date_thai = DateUtil.convertLongDateThai(tranfer_due_date)

    this.setState({
      startTranferDueDate: date,
      isOpenTranferDueDate: false,
      tranfer_due_date: tranfer_due_date,
      tranfer_due_date_thai: tranfer_due_date_thai
    })
  }

  handleOnChange(e) {
    let name = e.target.name;
    let val = e.target.value;

    this.setState({
      [name]: val
    })
  }

  onTranferByChanged(e) {
    let tranfer_by = e.currentTarget.value
    this.setState({
      tranfer_by: tranfer_by
    })
  }

  calOutstanding() {
    const { is_vat, vat } = this.state

    let real_sell_price = this.state.real_sell_price
    let cash_pledge = this.state.cash_pledge
    let is_cal_vat = this.state.is_cal_vat

    let vat_amount = Number(this.state.vat_amount)

    if (vat_amount !== 0) {
        if (is_vat === 1) {
          let vat_amount_cal = real_sell_price * (vat / 100);
          vat_amount = parseFloat(String(vat_amount_cal)).toFixed(0);
        }
    }else{
      if (is_cal_vat === 0) {
        let vat_amount_cal = Number(real_sell_price )* (vat / 100);
        vat_amount = parseFloat(String(vat_amount_cal)).toFixed(0);
      }
    }

    let total_amount = Number(real_sell_price) + Number(vat_amount)
    let outstanding = Number(total_amount) - cash_pledge

    this.setState({
      outstanding: outstanding,
      vat_amount,
      total_amount
    })
  }


  loadDataCarSellPay (car_sell, car_id, customer_id) {

    if (car_id === '' || customer_id === '') {
      return
    }

    axios.get(APIURL + '/car_sell_pay/buy/' +car_id + '/' + customer_id)
      .then(res => {
        let car_sell_pay_list = res.data

        let sum_pay = car_sell_pay_list.reduce((acc, pay)=>acc + pay.car_sell_pay_count, 0)

        let real_sell_price = this.state.real_sell_price
        let outstanding = Number(real_sell_price) - sum_pay

        this.setState({
          cash_pledge: sum_pay,
          outstanding: outstanding
        })

      }).catch(error => {
       console.log('error:', error)
    })
  }

  saveCarContract(action) {

    let contract_no = this.state.contract_no
    let write_at = this.state.write_at
    let contract_date = this.state.contract_date
    let seller_name = this.state.seller_name
    let car_name = this.state.car_name
    let car_license_plate = this.state.car_license_plate
    let car_num_engines = this.state.car_num_engines
    let car_number = this.state.car_number
    let car_year_name = this.state.car_year_name
    let car_color_name = this.state.car_color_name
    let buyer_name = this.state.buyer_name
    let buyer_age = this.state.buyer_age
    let buyer_address = this.state.buyer_address
    let buyer_mobile = this.state.buyer_mobile
    let card_type = this.state.card_type
    let card_code = this.state.card_code
    let card_out_at = this.state.card_out_at
    let real_sell_price = this.state.real_sell_price
    let cash_pledge = this.state.cash_pledge
    let paid_due_date = this.state.paid_due_date
    let car_id = this.state.car_id
    let customer_id = this.state.customer_id
    let car_sell_id = this.state.car_sell_id
    let contract_type = 'sell' // sell, buy
    //
    let contract_date_thai = this.state.contract_date_thai
    let paid_due_date_thai = this.state.paid_due_date_thai

    let tranfer_due_date = this.state.tranfer_due_date
    let tranfer_due_date_thai = this.state.tranfer_due_date_thai

    let note = this.state.note
    let tranfer_by = this.state.tranfer_by
    let seller_address = this.state.seller_address

    if (contract_no === '') {
      AlertWarning('กรุณากรอกเลขสัญญา');
      return
    }

    if(write_at === '') {
      AlertWarning('กรุณากรอกเขียนที่');
      return
    }

    if (contract_date_thai === null) {
      AlertWarning('กรุณาเลือกวันที่ทำสัญญา');
      return
    }

    if (paid_due_date_thai === null) {
      AlertWarning('กรุณาเลือกวันที่ครบกำหนดชำระ');
      return
    }

    if(port === 9025 || port === 9053){
      if (tranfer_due_date_thai === null) {
        AlertWarning('กรุณาเลือกวันที่โอน');
        return
      }
    }

    // vat , vat_amount, total_amount
    let vat = this.state.vat
    let vat_amount = this.state.vat_amount
    let total_amount = this.state.total_amount

    if (vat_amount === '0') {
      vat = 0
    }

    let data = {
      contract_no : contract_no,
      write_at : write_at,
      contract_date : contract_date,
      seller_name : seller_name,
      car_name : car_name,
      car_license_plate : car_license_plate,
      car_num_engines : car_num_engines,
      car_number : car_number,
      car_year_name : car_year_name,
      car_color_name : car_color_name,
      buyer_name : buyer_name,
      buyer_age : buyer_age,
      buyer_address : buyer_address,
      buyer_mobile : buyer_mobile,
      card_type : card_type,
      card_code : card_code,
      card_out_at : card_out_at,
      real_sell_price : real_sell_price,
      cash_pledge : cash_pledge,
      paid_due_date : paid_due_date,
      car_id : car_id,
      customer_id : customer_id,
      car_sell_id : car_sell_id,
      contract_type : contract_type,
      note : note,
      vat,
      vat_amount,
      total_amount,
      tranfer_due_date : tranfer_due_date,
      tranfer_by: tranfer_by,
      seller_address: seller_address
    }

    this.setState({
      isLoading: true,
    }, () => {
      if (action === 'add') {
        let url = APIURL + '/contract';
        axios.post( url, data)
          .then(res => {
            if (res.status === 200) {
              let resData = res.data
              let contract_id = resData.contract_id;
              AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว');
              this.setState({
                isLoading: false,
                contract_id: contract_id
                // isExist: true,
              })
            }
          }).catch(error => {
          console.log('error:', error)
        });
      } else if (action === 'edit') {
        let contract_id = this.state.contract_id
        let editData = {...data, contract_id: contract_id }
        let url = APIURL + '/contract';
        axios.put( url, editData)
          .then(res => {
            if (res.data === 'success') {
              AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว');
              this.setState({
                isLoading: false,
              })
            }
          }).catch(error => {
          console.log('error:', error)
        });
      }

    });

  }

  // ส่วนหัวแสดงโลโก้ แสดงที่อยู่บริษัท ปริ้นท์ 2 แผ่น
  // ** แก้ไขปรับเหลือแผ่นเดียว 18-8-66
  printCarContract() {
    let contract_no = this.state.contract_no
    let write_at = this.state.write_at
    let contract_date = this.state.contract_date
    let seller_name = this.state.seller_name
    let seller_address = this.state.seller_address === null ? '-' : this.state.seller_address
    let car_name = this.state.car_name
    let car_license_plate = this.state.car_license_plate
    let car_num_engines = this.state.car_num_engines !== '' ? this.state.car_num_engines : '-'
    car_num_engines = car_num_engines.toUpperCase()
    let car_number = this.state.car_number !== ''? this.state.car_number : '-'
    car_number = car_number.toUpperCase()
    let car_year_name = this.state.car_year_name
    let car_color_name = this.state.car_color_name
    let buyer_name = this.state.buyer_name
    let buyer_age = this.state.buyer_age
    let buyer_address = this.state.buyer_address
    let buyer_mobile = this.state.buyer_mobile
    let card_type = this.state.card_type
    let card_code = this.state.card_code
    let card_out_at = this.state.card_out_at !== '' ? this.state.card_out_at : '-'
    let real_sell_price = this.state.real_sell_price
    let real_sell_price_comma = NumberUtil.addCommasZeroInt(real_sell_price)
    let cash_pledge = this.state.cash_pledge
    let cash_pledge_comma = NumberUtil.addCommasZeroInt(cash_pledge)

    let paid_due_date = this.state.paid_due_date
    let car_id = this.state.car_id
    let customer_id = this.state.customer_id
    let car_sell_id = this.state.car_sell_id
    let contract_type = 'sell' // sell, buy
    // let date_of_issue_thai = this.state.date_of_issue_thai
    // let expiration_date_thai = this.state.expiration_date_thai
    let province_name = this.state.province_name
    //
    let contract_date_thai = this.state.contract_date_thai
    let paid_due_date_thai = this.state.paid_due_date_thai
    // let outstanding =  real_sell_price - cash_pledge
    let outstanding =  this.state.outstanding
    let real_sell_price_thai = ThaiBaht(this.state.real_sell_price)
    let cash_pledge_thai = ThaiBaht(cash_pledge)

    if (car_num_engines.length > 0) {
      car_num_engines = car_num_engines.toUpperCase()
    }

    if (car_number.length > 0) {
      car_number = car_number.toUpperCase()
    }

    let seller_address1 = ''
    let seller_address2 = ''

    if (seller_address !== '-') {
      if (seller_address.includes('จ.')) {
         let seller_address_split = seller_address.split('จ.')
         seller_address1 = seller_address_split[0]
         seller_address2='จ.'+seller_address_split[1]
      } else if (seller_address.includes('จังหวัด')) {
        let seller_address_split = seller_address.split('จังหวัด')
        seller_address1 = seller_address_split[0]
        seller_address2='จังหวัด'+seller_address_split[1]
      }
    }else{
      seller_address1 = seller_address
      seller_address2 = ''
    }

    let car_license = car_license_plate + ' ' + province_name

    let seller_name_pad = stringUtil.addSpacePaddingFill(seller_name, 42)
    let seller_address_pad1 = stringUtil.addSpacePaddingFill(seller_address1, 68)
    let seller_address_pad2 = stringUtil.addSpacePaddingFill(seller_address2, 68)

    let car_name_pad = stringUtil.addSpacePaddingFill(car_name, 54)
    let car_num_engines_pad = stringUtil.addSpacePaddingFill(car_num_engines, 44)
    let car_number_pad = stringUtil.addSpacePaddingFill(car_number, 48)
    let car_license_pad = stringUtil.addSpacePaddingFill(car_license, 48)
    let car_year_name_pad = stringUtil.addSpacePaddingFill(car_year_name, 16)
    let car_color_name_pad = stringUtil.addSpacePaddingFill(car_color_name, 16)
    let buyer_name_pad = stringUtil.addSpacePaddingFill(buyer_name, 60)
    let buyer_age_pad = stringUtil.addSpacePaddingRightLeft(buyer_age, 20)
    let card_type_pad = stringUtil.addSpacePaddingFill(card_type, 20)
    let card_code_pad = stringUtil.addSpacePaddingRightLeft(card_code, 24)
    let card_out_at_pad = stringUtil.addSpacePaddingRightLeft(card_out_at, 20)
    // let date_of_issue_thai_pad = stringUtil.addSpacePaddingRightLeft(date_of_issue_thai, 30)
    // let expiration_date_thai_pad = stringUtil.addSpacePaddingRightLeft(expiration_date_thai, 30)
    let buyer_address_pad = stringUtil.addSpacePaddingFill(buyer_address, 82)
    let buyer_mobile_pad = stringUtil.addSpacePaddingFill(buyer_mobile, 16)
    let paid_due_date_thai_pad = stringUtil.addSpacePaddingRightLeft(paid_due_date_thai, 40)

    let buyer_name_pad2 = stringUtil.addSpacePaddingFill(buyer_name, 40)
    let car_license_pad2 = stringUtil.addSpacePaddingFill(car_license, 40)

    let number_of_car_pad = stringUtil.addSpacePaddingFill('1', 10)
    let real_sell_price_pad = stringUtil.addSpacePaddingFill(real_sell_price_comma, 25)
    let real_sell_price_thai_pad = stringUtil.addSpacePaddingFill(real_sell_price_thai, 34)
    let cash_pledge_pad = stringUtil.addSpacePaddingFill(cash_pledge_comma, 16)
    let cash_pledge_thai_pad = stringUtil.addSpacePaddingFill(cash_pledge_thai, 36)

    let checq_pad = stringUtil.addSpacePaddingFill('_', 30)
    let blank_pad = stringUtil.addSpacePaddingFill('_', 50)

    let note = this.state.note

    // let vat = this.state.vat === 0 ? (this.state.company_setting !== null ? this.state.company_setting.vat : '7') : this.state.vat
    // let vat_amount = Number(this.state.vat_amount)
    // let total_amount = this.state.total_amount
    //
    // let tranfer_due_date_thai = this.state.tranfer_due_date_thai

    // let tranfer_by = this.state.tranfer_by
    // let tranfer_by_text = ''
    //
    // if(tranfer_by === 'ผู้ขาย'){
    //   tranfer_by_text = 'ผู้ขายจะทำการโอนกรรมสิทธิ์รถคันนี้ให้แก่ผู้ซื้อ ภายในวันที่ ' + ' ' + tranfer_due_date_thai
    // }else if(tranfer_by === 'ผู้ซื้อ'){
    //   tranfer_by_text = 'ผู้ซื้อจะทำการโอนกรรมสิทธิ์รถคันนี้ ภายในวันที่ ' + ' ' + tranfer_due_date_thai
    // }else {
    //   tranfer_by_text = 'ผู้ขายจะทำการโอนกรรมสิทธิ์รถคันนี้ให้แก่ผู้ซื้อทันที'
    // }


    let docDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'A4',
      pageMargins: [40, 15, 15, 0],
      content: [
        {
          columns: [
            { width: '100%', style: 'normal',
              text: '',
            },
          ],
          columnGap: 0,
          lineHeight:1
        },
        {
          table: {
            widths: [ '30%', '40%', '30%' ],
            body: [
              [
                {text: '', style: 'normal'},
                {
                  text: 'สัญญาซื้อขายรถยนต์',
                  style: 'bodyhead',
                  margin: [0, 0, 0, 12]
                },
                {text: '', style: 'normal'},
              ],
              [
                {
                  columns: [
                    { width: 'auto', style: 'normalLeft', text: 'No. ',},
                    { width: 'auto', style: ['normalLeft', 'bottomLine'], text: contract_no,},
                  ],
                  alignment: 'left',
                  columnGap: 5
                },
                {
                  columns: [
                    { width: '*', style: 'normalRight', text: 'เขียนที่ ',},
                    { width: 'auto', style: ['normalRight', 'bottomLine'], text: write_at,},
                  ],
                  colSpan: 2,
                  alignment: 'right',
                  columnGap: 8
                },
                ''
              ],
              [
                {
                  columns: [
                    { width: '*', style: 'normalRight', text: 'วันที่ ',},
                    { width: 'auto', style: ['normalRight', 'bottomLine'], text: contract_date_thai,},
                  ],
                  colSpan: 3,
                  alignment: 'right',
                  columnGap: 8
                },
                '',
                ''
              ],
              [
                {text: '', style: 'normalRight', colSpan: 3},
                '',
                ''
              ],
            ],
          },
          layout: 'noBorders',
          margin: [0, 0, 0, 3]
        },
        {
          columns: [
            { width: 12, style: 'normal', text: ' ',},
            { width: 36, style: 'normal', text: 'ข้าพเจ้า',},
            { width: '29%', style: ['normal', 'bottomLine'], text: seller_name_pad,},
            { width: 30, style: 'normal', text: 'ที่อยู่',},
            { width: '*', style: ['normalLeft', 'bottomLine'], text: seller_address_pad1,},
          ],
          columnGap: 10,
          lineHeight:0.9
        },
        {
          columns: [
            { width: 20, style: 'normal', text: ' ',},
            { width: 236, style: ['normalLeft', 'bottomLine'], text: seller_address_pad2,},
            { width: 130, style: 'normal', text: 'ซึ่งต่อไปนี้เรียกว่า "ผู้ขาย"',},
          ],
          columnGap: 0,
          lineHeight: 0.9
        },
        {
          columns: [
            { width: 64, style: 'normal', text: 'ฝ่ายหนึ่งกับ',},
            { width: 210, style: ['normalLeft', 'bottomLine'], text: buyer_name_pad,},
            { width: 48, style: 'normal', text: 'อายุ',},
            { width: 60, style: ['normalLeft', 'bottomLine'], text: buyer_age_pad,},
          ],
          columnGap: 0,
          lineHeight: 0.9
        },
        {
          columns: [
            { width: 40, style: 'normal', text: 'ที่อยู่',},
            { width: 370, style: ['normalLeft', 'bottomLine'], text: buyer_address_pad,},
            { width: 130, style: 'normal', text: 'ซึ่งต่อไปนี้เรียกว่า "ผู้ซื้อ"',},
          ],
          columnGap: 0,
          lineHeight: 0.9
        },
        {
          columns: [
            { width: 300, style: 'normal', text: 'อีกฝ่ายหนึ่ง ทั้งสองฝ่ายตกลงทำสัญญาซื้อของมีข้อความดังต่อไปนี้',},
          ],
          columnGap: 0,
          lineHeight: 0.9
        },
        {
          columns: [
            { width: 12, style: 'normal', text: ' ',},
            { width: 200, style: 'normal', text: '๑. ผู้ซื้อตกลงซื้อ ผู้ขายตกลงขายรถยนต์ยี่ห้อ',},
            { width: '29%', style: ['normal', 'bottomLine'], text: car_name_pad,},
          ],
          columnGap: 10,
          lineHeight:0.9
        },
        {
          columns: [
            { width: 88, style: 'normal', text: 'หมายเลขทะเบียน',},
            { width: '*', style: ['normalLeft', 'bottomLine'], text: car_license_pad,},
            { width: 92, style: 'normal', text: 'หมายเลขเครื่องยนต์',},
            { width: '30%', style: ['normalLeft', 'bottomLine'], text: car_num_engines_pad,},
          ],
          columnGap: 10,
          lineHeight:0.9
        },
        {
          columns: [

            { width: 78, style: 'normal', text: 'หมายเลขตัวถัง',},
            { width: '37%', style: ['normalLeft', 'bottomLine'], text: car_number_pad,},
            { width: 56, style: 'normal', text: 'จำนวน',},
            { width: 32, style: ['normal','bottomLine'], text: number_of_car_pad,},
            { width: 25, style: 'normal', text: 'คัน',},
          ],
          columnGap: 0,
          lineHeight: 0.9
        },
        {
          columns: [
            { width: 78, style: 'normal', text: 'ในราคา',},
            { width: 80, style: ['normalLeft', 'bottomLine'], text: real_sell_price_pad ,},
            { width: 26, style: 'normal', text: 'บาท',},
            { width: 8, style: 'normal', text: '(',},
            { width: 'auto', style: ['normalCenter','bottomLine'], text: real_sell_price_thai_pad,},
            { width: 20, style: 'normal', text: ')',margin: [8,0,0,0]},
          ],
          columnGap: 0,
          lineHeight: 0.9
        },
        {
          columns: [
            { width: 'auto', style: 'normal', text: 'ผู้ซื้อได้ตรวจสอบสภาพของรถและเครื่องยนต์เป็นที่พอใจแล้ว',},
          ],
          columnGap: 0,
          lineHeight: 0.9
        },
        {
          columns: [
            { width: 12, style: 'normal', text: ' ',},
            { width: 274, style: 'normal', text: '๒. ในวันลงนามสัญญาผู้ซื้อได้วางมัดจำไว้เป็นเช็ค/เงินสด จำนวนเงิน',},
            { width: 30, style: ['normalCenter', 'bottomLine'], text: cash_pledge_pad,},
            { width: 18, style: 'normal', text: 'บาท',},
            { width: 5, style: 'normal', text: '(',},
            { width: 140, style: ['normalCenter','bottomLine'], text: cash_pledge_thai_pad,},
            { width: 10, style: 'normal', text: ')',margin: [6,0,0,0]},
          ],
          columnGap: 10,
          lineHeight:0.9
        },
        {
          columns: [
            { width: 72, style: 'normal', text: 'หมายเลขเช็ค',},
            { width: 90, style: ['normalLeft', 'bottomLine'], text: checq_pad,},
            { width: 56, style: 'normal', text: 'ธนาคาร',},
            { width: 90, style: ['normalLeft', 'bottomLine'], text: checq_pad,},
            { width: 56, style: 'normal', text: 'ลงวันที่',},
            { width: 90, style: ['normalLeft', 'bottomLine'], text: checq_pad,},
          ],
          columnGap: 0,
          lineHeight: 0.9
        },
        {
          columns: [
            { width: 150, style: 'normal', text: 'ส่วนที่เหลือจะชำระในวันที่',},
            { width: 120, style: ['normalLeft', 'bottomLine'], text: paid_due_date_thai_pad,},
            { width: 220, style: 'normal', text: 'ถ้าผู้ซื้อไม่ชำระค่ารถยนต์ส่วนที่เหลือในเวลาดังกล่าว',},
          ],
          columnGap: 0,
          lineHeight: 0.9
        },
        {
          columns: [
            { width: 270, style: 'normal', text: 'ผู้ขายมีสิทธิ์ริบมัดจำ ให้สัญญาซื้อขายเป็นอันยกเลิก',},
          ],
          columnGap: 0,
          lineHeight: 0.9
        },
        {
          columns: [
            { width: 12, style: 'normal', text: ' ',},
            { width: 500, style: 'normal', text: '๓. ผู้ขายจะมอบรถยนต์ เอกสารเรื่องราวขอโอนและขอรับโอนทะเบียนรถพร้อมสมุดคู่มือทะเบียนรถให้แก่ผู้ซื้อในวันที่ผู้ขาย',},

          ],
          columnGap: 10,
          lineHeight:0.9
        },
        {
          columns: [
            { width: 500, style: 'normal', text: 'ได้รับชำระเงินค่ารถยนต์ครบถ้วน',},
          ],
          columnGap: 10,
          lineHeight:0.9
        },
        {
          columns: [
            { width: 12, style: 'normal', text: ' ',},
            { width: 250, style: 'normal', text: 'ค่าธรรมเนียมค่าใช้จ่ายในการโอนเปลี่ยนแปลงและต่อทะเบียน',},
            { width: 140, style: ['normalLeft', 'bottomLine'], text: blank_pad,},
            { width: 50, style: 'normal', text: 'เป็นผู้ออก',},
          ],
          columnGap: 10,
          lineHeight:0.9
        },
        {
          columns: [
            { width: 12, style: 'normal', text: ' ',},
            { width: 500, style: 'normal', text: '๔. รถยนต์ และเอกสารเรื่องราวขอโอน และขอรับโอนทะเบียนรถที่ผู้ซื้อได้รับมอบไปแล้วถ้าผู้ซื้อไม่นำไปเปลี่ยนแปลงแก้ไข',},
          ],
          columnGap: 10,
          lineHeight:0.9
        },
        {
          columns: [
            { width: 500, style: 'normal', text: 'ให้ถูกต้องไม่ว่าด้วยเหตุใดๆ ก็ดี หรือนำรถยนต์ไปใช้ทางก่อเหตุใดๆ อันไม่ชอบด้วยกฎหมาย ผู้ซื้อเป็นฝ่ายรับผิดชอบแต่ผู้เดียว',},
          ],
          columnGap: 10,
          lineHeight:0.9
        },
        {
          columns: [
            { width: 500, style: 'normal', text: 'สัญญานี้ทำขึ้นเป็นสองฉบับมีข้อความถูกต้องตรงกันทุกประการทั้งสองฝ่ายได้อ่าน และเข้าใจข้อความด้วยดีโดยตลอด',},
          ],
          columnGap: 10,
          lineHeight:0.9
        },
        {
          columns: [
            { width: 500, style: 'normal', text: 'จึงลงลายมือชื่อไว้เป็นหลักฐานต่อหน้าพยาน',},
          ],
          columnGap: 10,
          lineHeight:0.9
        },
       /*
       {
          columns: [
            { width: 88, style: 'normal', text: 'หมายเลขทะเบียน',},
            { width: '36%', style: ['normalLeft', 'bottomLine'], text: car_license_pad,},
            { width: 34, style: 'normal', text: 'ปี',},
            { width: '12%', style: ['normalLeft', 'bottomLine'], text: car_year_name_pad,},
            { width: 26, style: 'normal', text: 'สี',},
            { width: '12%', style: ['normalLeft', 'bottomLine'], text: car_color_name_pad,},
          ],
          columnGap: 0,
          lineHeight: 0.9
        },*/
        {
          columns: [
            {width: '100%', text: '.', style: 'normalWhite'},
          ],
          lineHeight: 0.2
        },
        {
          columns: [

          ],
          columnGap: 0,
          lineHeight: 0.9
        },
        {
          columns: [
            {
              width: '45%',
              style: 'normal',
              text: 'หมายเหตุ...............................................................'
            },
            {
              width: '5%',
              style: 'normal',
              text: ''
            },
            {
              width: '50%',
              style: 'normal',
              text: 'ลงชื่อ...............................................................................ผู้ขาย'
            }
          ],
          columnGap: 0,
          lineHeight: 0.9,
          margin: [0, 5, 0, 0]
        },
        {
          columns: [
            {
              width: '7%',
              style: 'normal',
              text: ''
            },
            {
              width: '38%',
              style: 'normal',
              text: '...............................................................'
            },
            {
              width: '10%',
              style: 'normal',
              text: ''
            },
            {
              width: '45%',
              style: 'normal',
              text: '...............................................................................'
            }
          ],
          columnGap: 0,
          lineHeight: 0.9,
          margin: [0, 5, 0, 0]
        },
        {
          columns: [
            {
              width: '7%',
              style: 'normal',
              text: ''
            },
            {
              width: '38%',
              style: 'normal',
              text: '...............................................................'
            },
            {
              width: '5%',
              style: 'normal',
              text: ''
            },
            {
              width: '50%',
              style: 'normal',
              text: 'ลงชื่อ...............................................................................ผู้ซื้อ'
            }
          ],
          columnGap: 0,
          lineHeight: 0.9,
          margin: [0, 5, 0, 0]
        },
        {
          columns: [
            {
              width: '7%',
              style: 'normal',
              text: ''
            },
            {
              width: '38%',
              style: 'normal',
              text: '...............................................................'
            },
            {
              width: '10%',
              style: 'normal',
              text: ''
            },
            {
              width: '45%',
              style: 'normal',
              text: '...............................................................................'
            }
          ],
          columnGap: 0,
          lineHeight: 0.9,
          margin: [0, 5, 0, 0]
        },
        {
          columns: [
            {
              width: '7%',
              style: 'normal',
              text: ''
            },
            {
              width: '38%',
              style: 'normal',
              text: '...............................................................'
            },
            {
              width: '5%',
              style: 'normal',
              text: ''
            },
            {
              width: '50%',
              style: 'normal',
              text: 'ลงชื่อ...............................................................................พยาน'
            }
          ],
          columnGap: 0,
          lineHeight: 0.9,
          margin: [0, 5, 0, 0]
        },
        {
          columns: [
            {
              width: '7%',
              style: 'normal',
              text: ''
            },
            {
              width: '38%',
              style: 'normal',
              text: '...............................................................'
            },
            {
              width: '10%',
              style: 'normal',
              text: ''
            },
            {
              width: '45%',
              style: 'normal',
              text: '...............................................................................'
            }
          ],
          columnGap: 0,
          lineHeight: 0.9,
          margin: [0, 5, 0, 0]
        },
        {
          columns: [
            {
              width: '7%',
              style: 'normal',
              text: ''
            },
            {
              width: '38%',
              style: 'normal',
              text: '...............................................................'
            },
            {
              width: '5%',
              style: 'normal',
              text: ''
            },
            {
              width: '50%',
              style: 'normal',
              text: 'ลงชื่อ...............................................................................พยาน'
            }
          ],
          columnGap: 0,
          lineHeight: 0.9,
          margin: [0, 5, 0, 0]
        },
        {
          columns: [
            {
              width: '7%',
              style: 'normal',
              text: ''
            },
            {
              width: '38%',
              style: 'normal',
              text: '...............................................................'
            },
            {
              width: '10%',
              style: 'normal',
              text: ''
            },
            {
              width: '45%',
              style: 'normal',
              text: '...............................................................................'
            }
          ],
          columnGap: 0,
          lineHeight: 0.9,
          margin: [0, 5, 0, 0]
        },

        {
          columns: [
            { width: 75, style: 'normal', text: 'ข้าพเจ้าผู้ซื้อ',},
            { width: 165, style: ['normal', 'bottomLine'], text: buyer_name_pad2,},
            { width: 84, style: 'normal', text: 'ได้รับรถยนต์ยี่ห้อ',},
            { width: 280, style: ['normal', 'bottomLine'], text: car_name_pad,},
          ],
          columnGap: 0,
          lineHeight: 0.9,
          margin: [0, 10, 0, 0]
        }
        ,
        {
          columns: [
            { width: 88, style: 'normal', text: 'หมายเลขทะเบียน',},
            { width: 150, style: ['normal', 'bottomLine'], text: car_license_pad2,},
            { width: 300, style: 'normal', text: 'ไปแล้วอยู่ในสภาพพอใจทุกประการถ้าหากเกิดการเสียหายด้วยกรณีใดๆ ก็ดี',},
          ],
          columnGap: 0,
          lineHeight: 0.9,
          margin: [0, 0, 0, 0]
        },
        {
          columns: [
            { width: 500, style: 'normal', text: 'ผู้ซื้อยอมชดใช้ค่าเสียหายทุกประการถ้าหากเกิดการเสียหายจากคดีแพ่งหรือคดีอาญาใดๆ ก็ตาม ผู้ซื้อยินยอมชดใช้ให้ทั้งสิ้น',},
          ],
          columnGap: 0,
          lineHeight: 0.9,
        },

        {
          columns: [
            {
              width: '5%',
              style: 'normal',
              text: ''
            },
            {
              width: '45%',
              style: 'normal',
              text: 'โทร...............................................................ผู้ซื้อ',
              margin: [0, 5, 0, 0]
            },
            {
              width: '5%',
              style: 'normal',
              text: ''
            },
            {
              width: '45%',
              style: 'normal',
              text: 'ลงนาม.....................................................................ผู้ซื้อ'
            }
          ],
          columnGap: 0,
          lineHeight: 0.9,
          margin: [0, 10, 0, 0]
        },
        {
          columns: [
            {
              width: '5%',
              style: 'normal',
              text: ''
            },
            {
              width: '45%',
              style: 'normal',
              text: 'โทร...............................................................ผู้ขาย'
            },
            {
              width: '5%',
              style: 'normal',
              text: ''
            },
            {
              width: '45%',
              style: 'normal',
              text: 'ลงนาม.....................................................................พยาน'
            }
          ],
          columnGap: 0,
          lineHeight: 0.9,
          margin: [0, 5, 0, 0]
        },
        {
          columns: [
            {
              width: '5%',
              style: 'normal',
              text: ''
            },
            {
              width: '45%',
              style: 'normal',
              text: ''
            },
            {
              width: '5%',
              style: 'normal',
              text: ''
            },
            {
              width: '45%',
              style: 'normal',
              text: 'ลงนาม.....................................................................พยาน'
            }
          ],
          columnGap: 0,
          lineHeight: 0.9,
          margin: [0, 5, 0, 0]
        },
        {
          columns: [
            {
              width: '100%',
              style: 'textBold',
              text: note === '' || note === null ? 'หมายเหตุ ....................................................................................................................................................................................................'
                  :
                  'หมายเหตุ ' + ' ' + note
            },
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 10, 0, 0,]
        }

      ],
      styles: {
        bodyhead: {
          fontSize: 16,
          bold: true,
          alignment: 'center'
        },
        normal: {
          fontSize: 15,
        },
        textBold: {
          fontSize: 15,
          bold: true,
        },
        normalRight: {
          fontSize: 15,
          alignment: 'right'
        },
        normalLeft: {
          fontSize: 15,
          alignment: 'left',
        },
        normalCenter: {
          fontSize: 15,
          alignment: 'center',
        },
        textBold2: {
          fontSize: 14,
          bold: true,
        },
        bottomLine: {
          decoration: 'underline',
          decorationStyle: 'dotted',
        },
        normalWhite: {
          fontSize: 15,
          color : '#ffffff',
        },
      },
      defaultStyle: {
        font: 'THSarabunNew'
      }
    };
    pdfMake.createPdf(docDefinition).open();
  }


  render() {

    let tranfer_by_text = ''
    if(this.state.tranfer_by === 'ผู้ขาย'){
      tranfer_by_text = 'ผู้ขายจะทำการโอนกรรมสิทธิ์รถคันนี้ให้แก่ผู้ซื้อ ภายในวันที่ ' + ' ' + this.state.tranfer_due_date_thai
    }else if(this.state.tranfer_by === 'ผู้ซื้อ'){
      tranfer_by_text = 'ผู้ซื้อจะทำการโอนกรรมสิทธิ์รถคันนี้ ภายในวันที่ ' + ' ' + this.state.tranfer_due_date_thai
    }else {
      tranfer_by_text = 'ผู้ขายจะทำการโอนกรรมสิทธิ์รถคันนี้ให้แก่ผู้ซื้อทันที'
    }

    // ThaiBaht(cash_pledge)
    let cash_pledge = this.state.cash_pledge === 0 ? 'ศูนย์บาท' : ThaiBaht(this.state.cash_pledge)

    return (
      <div className="row">

        <Loading isLoading={this.state.isLoading} />

        <div className="col-12">
          <div className="card">

            <div className="card-header">
              <strong className="text-title">สัญญาซื้อขายรถยนต์ carok</strong>
            </div>

            <div className="card-block" style={{paddingLeft: 80, paddingRight: 80, paddingTop: 10}} >

              <div className="row" style={{lineHeight: 2.0}}>

                <table style={styles.table}>
                  <tbody>
                    <tr>
                      <td width={'30%'} style={styles.tdContentCenter} >
                        &nbsp;
                      </td>
                      <td style={styles.tdContentCenter}>
                        <strong style={{fontSize: 18}}> สัญญาซื้อขายรถยนต์ </strong>
                      </td>
                      <td width={'30%'}>&nbsp;</td>
                    </tr>

                    <tr>
                      <td style={styles.tdContentLeft}  >
                        <div className="form-inline mt-5 mb-5">
                          No.
                          <input className="form-control ml-4"
                                 style={styles.inputDataNum}
                                 value={this.state.contract_no || ''}
                                 onChange={(e) => {
                                   this.setState({
                                     contract_no: e.target.value
                                   })
                                 }}
                          />
                        </div>
                      </td>
                      <td>&nbsp;</td>
                      <td style={styles.tdContentRight} >

                        <div className="form-inline mt-5 mb-3" style={{justifyContent: 'right' }}>
                          เขียนที่ <input className="form-control ml-4"
                                        style={styles.inputDataWriteAt}
                                        value={this.state.write_at}
                                        onChange={(e) => {
                                          this.setState({
                                            write_at: e.target.value
                                          })
                                        }}
                        />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td style={styles.tdContentLeft}  > &nbsp; </td>
                      <td>&nbsp;</td>
                      <td style={styles.tdContentRight} >
                        <div className="mb-4 row" style={{justifyContent: 'right' }}>
                          วันที่ &nbsp;
                          <input readOnly={true}
                                 className="form-control"
                                 style={{width: 152}}
                                 value={this.state.contract_date_thai === null ? '' : this.state.contract_date_thai}
                          />
                          <div style={styles.iconCalendar}>
                            <DatePicker
                                selected={this.state.startDateContract}
                                onChange={this.handleDateContract}
                                dateFormat="yyyy-MM-dd"
                                customInput={<DatePickerIcon />}
                            />
                          </div>
                          {
                            this.state.isOpenDateContract && (
                              <DatePicker
                                selected={this.state.startDateContract}
                                onChange={this.handleDateContract}
                                dateFormat="yyyy-MM-dd"
                                inline
                              />
                            )
                          }
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline">
                          <span style={{width: 130, textAlign: 'left'}} >ข้าพเจ้า</span>
                          <input className="form-control ml-2"
                                 style={styles.inputLong}
                                 name="seller_name"
                                 value={this.state.seller_name}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 50, textAlign: 'left'}} >ที่อยู่</span>
                          <input className="form-control ml-2"
                                 style={{width: 400}}
                                 name="seller_address"
                                 value={this.state.seller_address}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp; ซึ่งต่อไปนี้เรียกว่า "ผู้ขาย"
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-3">
                          <span style={{width: 130, textAlign: 'left'}} >ฝ่ายหนึ่งกับ</span>
                          <input className="form-control ml-2"
                                 style={styles.inputLong}
                                 name="buyer_name"
                                 value={this.state.buyer_name}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 30, textAlign: 'center'}} >อายุ</span>
                          <input className="form-control ml-3"
                                 style={{...styles.inputShort, width: 52}}
                                 name="buyer_age"
                                 value={this.state.buyer_age}
                                 onChange={this.handleOnChange}
                                 inputMode={'numeric'}
                                 maxLength={2}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                          <span style={{width: 40, textAlign: 'left'}} >ที่อยู่</span>
                          <input className="form-control ml-2"
                                 style={{width: 300}}
                                 name="buyer_address"
                                 value={this.state.buyer_address}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp; ซึ่งต่อไปนี้จะเรียกว่า "ผู้ซื้อ"

                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                           อีกฝ่ายหนึ่ง ทั้งสองฝ่ายตกลงทำสัญญาซื้อของมีข้อความดังต่อไปนี้
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2" style={{marginLeft: 80}}>
                          <span style={{width: 280, textAlign: 'left'}} >๑. ผู้ซื้อตกลงซื้อ ผู้ขายตกลงขายรถยนต์ยี่ห้อ</span>
                          <input className="form-control ml-2"
                                 style={styles.inputLong}
                                 name="car_name"
                                 value={this.state.car_name}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 135}} >หมายเลขทะเบียน</span>
                          <input className="form-control ml-2"
                                 style={{width: 140}}
                                 name="car_license_plate"
                                 value={this.state.car_license_plate}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;
                          <input className="form-control ml-2"
                                 style={{width: 140}}
                                 name="province_name"
                                 disabled
                                 value={this.state.province_name}
                              //onChange={this.handleOnChange}
                          />
                        </div>
                        <div className="form-inline mt-2" style={{marginLeft: 0}}>
                          <span style={{width: 135, textAlign: 'left'}} >หมายเลขเครื่องยนต์</span>
                          <input className="form-control ml-2"
                                 style={{...styles.inputLong,...styles.toUpper}}
                                 name="car_num_engines"
                                 value={this.state.car_num_engines}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 125}}  >หมายเลขตัวถัง</span>
                          <input className="form-control ml-1"
                                 style={{...styles.inputLong,...styles.toUpper}}
                                 name="car_number"
                                 value={this.state.car_number}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 125}}  >จำนวน&nbsp;&nbsp;&nbsp; 1 &nbsp;&nbsp;&nbsp;คัน</span>

                        </div>
                        <div className="form-inline mt-2" style={{marginLeft: 0}}>
                          <span style={{width: 50}}> ในราคา </span>
                          <NumberFormat
                              className="form-control ml-2"
                              style={styles.inputLong}
                              thousandSeparator={true}
                              prefix={''}
                              placeholder=""
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              allowNegative={false}
                              value={this.state.real_sell_price}
                              onValueChange={(values) => {
                                const {value} = values

                                if(value === ''){
                                  return
                                }
                                this.setState({real_sell_price: value}, () => this.calOutstanding())
                              }}
                          />
                          <span style={{width: 30}} className="ml-2" >บาท</span>
                          <span className="ml-1" >({ThaiBaht(this.state.real_sell_price)})</span>
                          <span className="ml-2" >
                            ผู้ซื้อได้ตรวจสอบสภาพของรถและเครื่องยนต์เป็นที่พอใจแล้ว
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2" style={{marginLeft: 80}}>
                          <span style={{width: 400, textAlign: 'left'}} >
                            ๒. ในวันลงนามสัญญาผู้ซื้อได้วางมัดจำไว้เป็นเช็ค/เงินสด จำนวนเงิน
                          </span>
                          <NumberFormat
                              className="form-control ml-2"
                              style={styles.inputShort}
                              thousandSeparator={true}
                              prefix={''}
                              placeholder=""
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              allowNegative={false}
                              value={this.state.cash_pledge}
                              onValueChange={(values) => {
                                const {value} = values
                                this.setState({cash_pledge: value}, () => this.calOutstanding())
                              }}
                          />
                          <span style={{width: 30}} className="ml-2" >บาท</span>
                          <span className="ml-2" >({cash_pledge})</span>
                        </div>
                        <div className="form-inline mt-1">
                          หมายเลขเช็ค......................................................ธนาคาร...............................ลงวันที่.................................
                          ส่วนที่เหลือจะชำระในวันที่
                          <input readOnly={true}
                                 className="form-control ml-3"
                                 name="paid_due_date_thai"
                                 style={{width: 160, height: 38}}
                                 value={this.state.paid_due_date_thai === null ? '' : this.state.paid_due_date_thai}
                          />
                          <div style={styles.iconCalendar}>
                            <DatePicker
                                selected={this.state.startPaidDueDate}
                                onChange={this.handlePaidDueDate}
                                dateFormat="yyyy-MM-dd"
                                customInput={<DatePickerIcon />}
                            />
                          </div>

                          {
                            this.state.isOpenPaidDueDate && (
                                <DatePicker
                                    selected={this.state.startPaidDueDate}
                                    onChange={this.handlePaidDueDate}
                                    dateFormat="yyyy-MM-dd"
                                    inline
                                />
                            )
                          }
                        </div>
                        <div className="form-inline mt-1">
                          ถ้าผู้ซื้อไม่ชำระค่ารถยนต์ส่วนที่เหลือในเวลาดังกล่าว ผู้ขายมีสิทธิ์ริบมัดจำ ให้สัญญาซื้อขายเป็นอันยกเลิก
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2" style={{marginLeft: 80}}>
                           <span style={{textAlign: 'left'}} >๓. ผู้ขายจะมอบรถยนต์ เอกสารเรื่องราวขอโอนและขอรับโอนทะเบียนรถพร้อมสมุดคู่มือทะเบียนรถให้แก่ผู้ซื้อในวันที่ผู้ขายได้รับชำระเงินค่ารถยนต์ครบถ้วน</span>
                        </div>
                        <div className="form-inline mt-1">
                          ค่าธรรมเนียมค่าใช้จ่ายในการโอนเปลี่ยนแปลงและต่อทะเบียน..............................................................................เป็นผู้ออก
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2" style={{marginLeft: 80}}>
                          <span style={{textAlign: 'left'}} >๔. รถยนต์ และเอกสารเรื่องราวขอโอน และขอรับโอนทะเบียนรถที่ผู้ซื้อได้รับมอบไปแล้วถ้าผู้ซื้อไม่นำไปเปลี่ยนแปลงแก้ไข</span>
                        </div>
                        <div className="form-inline mt-1">
                          ให้ถูกต้องไม่ว่าด้วยเหตุใดๆ ก็ดี หรือนำรถยนต์ไปใช้ทางก่อเหตุใดๆ อันไม่ชอบด้วยกฎหมาย ผู้ซื้อเป็นฝ่ายรับผิดชอบแต่ผู้เดียว
                        </div>
                        <div className="form-inline mt-1">
                          สัญญานี้ทำขึ้นเป็นสองฉบับมีข้อความถูกต้องตรงกันทุกประการทั้งสองฝ่ายได้อ่าน และเข้าใจข้อความด้วยดีโดยตลอด จึงลงลายมือชื่อไว้เป็นหลักฐานต่อหน้าพยาน
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-3">
                          <table style={styles.table}>
                            <tbody>
                            <tr className="mt-3">
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                <span style={{width: 0, textAlign: 'left'}} >
                                  หมายเหตุ.......................................................................................................
                                </span>
                              </td>
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                ลงชื่อ..............................................................................ผู้ขาย
                              </td>
                            </tr>
                            <tr >
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                <span style={{marginLeft: 58, textAlign: 'left'}} >.......................................................................................................</span>
                              </td>
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                <span style={{marginLeft: 30, textAlign: 'left'}} >.....................................................................................</span>
                              </td>
                            </tr>
                            <tr >
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                <span style={{marginLeft: 58, textAlign: 'left'}} >.......................................................................................................</span>
                              </td>
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                ลงชื่อ..............................................................................ผู้ซื้อ
                              </td>
                            </tr>
                            <tr >
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                <span style={{marginLeft: 58, textAlign: 'left'}} >.......................................................................................................</span>
                              </td>
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                <span style={{marginLeft: 30, textAlign: 'left'}} >.....................................................................................</span>
                              </td>
                            </tr>
                            <tr >
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                <span style={{marginLeft: 58, textAlign: 'left'}} >.......................................................................................................</span>
                              </td>
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                ลงชื่อ..............................................................................พยาน
                              </td>
                            </tr>
                            <tr >
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                <span style={{marginLeft: 58, textAlign: 'left'}} >.......................................................................................................</span>
                              </td>
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                <span style={{marginLeft: 30, textAlign: 'left'}} >.....................................................................................</span>
                              </td>
                            </tr>
                            <tr >
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                <span style={{marginLeft: 58, textAlign: 'left'}} >.......................................................................................................</span>
                              </td>
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                ลงชื่อ..............................................................................พยาน
                              </td>
                            </tr>
                            <tr >
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                <span style={{marginLeft: 58, textAlign: 'left'}} >.......................................................................................................</span>
                              </td>
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                <span style={{marginLeft: 30, textAlign: 'left'}} >.....................................................................................</span>
                              </td>
                            </tr>
                            <tr >
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                <span style={{marginLeft: 58, textAlign: 'left'}} ></span>
                              </td>
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                วันที่..................เดือน.........................................พ.ศ....................
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        
                      </td>
                    </tr>

                    <tr>
                      <td style={{width: '100%'}} colSpan={3}>
                        <div className="mt-3"  >
                          <table style={styles.table}>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="form-inline mt-2">
                                     <span style={{width: 180, textAlign: 'left'}} >ข้าพเจ้าผู้ซื้อ นาย/นาง/นางสาว</span>
                                     <input className="form-control ml-2"
                                           style={styles.inputLong}
                                           value={this.state.buyer_name}
                                           onChange={(e) => {}}
                                     />
                                    <span style={{width: 120, textAlign: 'center'}} >ได้รับรถยนต์</span>
                                    <input className="form-control ml-2"
                                           style={styles.inputLong}
                                           name="car_name"
                                           value={this.state.car_name}
                                           onChange={(e) => {}}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="form-inline mt-2">
                                    <span style={{width: 150}} >หมายเลขทะเบียน</span>
                                    <input className="form-control ml-2"
                                           style={{width: 140}}
                                           name="car_license_plate"
                                           value={this.state.car_license_plate}
                                           onChange={this.handleOnChange}
                                    />
                                    <input className="form-control ml-2"
                                           style={{width: 140}}
                                           name="province_name"
                                           disabled
                                           value={this.state.province_name}
                                        //onChange={this.handleOnChange}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span> ไปแล้วอยู่ในสภาพพอใจทุกประการถ้าหากเกิดการเสียหายด้วยกรณีใดๆ ก็ดี  </span>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="form-inline mt-2">
                                    <span> ผู้ซื้อยอมชดใช้ค่าเสียหายทุกประการถ้าหากเกิดการเสียหายจากคดีแพ่งหรือคดีอาญาใดๆ ก็ตาม ผู้ซื้อยินยอมชดใช้ให้ทั้งสิ้น </span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <table style={styles.table}>
                            <tbody>
                            <tr>
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                <span style={{marginLeft: 58, textAlign: 'left'}} >
                                  โทร............................................................................ผู้ซื้อ
                                </span>
                              </td>
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                ลงนาม..............................................................................ผู้ซื้อ
                              </td>
                            </tr>
                            <tr >
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                <span style={{marginLeft: 58, textAlign: 'left'}} >
                                  โทร............................................................................ผู้ขาย
                                </span>
                              </td>
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                ลงนาม..............................................................................ผู้ขาย
                              </td>
                            </tr>
                            <tr>
                              <td style={{...styles.tdContentLeft, width: '50%'}} >

                              </td>
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                ลงนาม..............................................................................พยาน
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 90, textAlign: 'left'}} > หมายเหตุ </span>
                          <input className="form-control"
                                 style={{width: '80%'}}
                                 name="note"
                                 value={this.state.note}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>

                  </tbody>
                </table>
              </div>

              <div className="col-md-12 text-right">
                <div className="card-block">

                  {
                    this.state.contract_id === null ? (
                      <button type="button"
                              className="btn btn-success btn-md mr-2"
                              style={{width: 120}}
                              onClick={() => this.saveCarContract('add')}
                      >
                        <i className="icon-check"/>
                        &nbsp;บันทึก
                      </button>
                    ) : <button type="button"
                                className="btn btn-warning btn-md mr-2"
                                style={{width: 120}}
                                onClick={() => this.saveCarContract('edit')}
                    >
                      <i className="icon-pencil"/>
                      &nbsp;แก้ไข
                    </button>
                  }

                  {
                    this.state.contract_id === null ? null : (
                      <button type="button"
                              className="btn btn-primary btn-md mr-2"
                              style={{width: 120}}
                              onClick={() => {
                                this.printCarContract()
                                /*if (PORT === 9028){
                                  this.printCarContract45Car()
                                }else {
                                  this.printCarContract()
                                }*/
                              }}
                      >
                        <i className="icon-printer"/>  พิมพ์
                      </button>
                    )
                  }
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }

}


const styles = {
  table: {
    width: '100%',
    maxWidth: '100%'
  },
  tdContentCenter: {
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  tdContentRight: {
    textAlign: 'right',
    verticalAlign: 'middle',
    justifyContent: 'right',
  },
  tdContentLeft: {
    textAlign: 'left',
    verticalAlign: 'middle'
  },
  tableTh:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  tableTdLeft:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'center',
    verticalAlign: 'top',
  },
  tableTdCenter:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'left',
    verticalAlign: 'middle',
    paddingLeft: '70px',
  },
  tableTdRigth:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'right',
    verticalAlign: 'top',
  },
  tablePd:{
    paddingLeft: '50px',
    paddingRight: '50px',
    lineHeight: '2.2',
  },
  amountsText:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'center',
    verticalAlign: 'middle',
    fontWeight: 'bold',
  },
  amountsTextNoBorder:{
    maxWidth: '100%',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  amountsRigth:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'right',
    verticalAlign: 'top',
    fontWeight: 'bold',
  },
  buttonHeight: {
    height: 32,
  },
  inputData:{
    borderRadius: '5px',
    width: '450px',
  },
  inputDataNum:{
    borderRadius: '5px',
    width: '100px'
  },
  inputDataWriteAt:{
    borderRadius: '5px',
    width: '180px'
  },
  inputShort: {
    borderRadius: '5px',
    width: '120px'
  },
  inputLong: {
    borderRadius: '1px',
    width: '220px'
  },
  toUpper: {
    textTransform: 'uppercase',
  },
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarSalesContractCarOk)
