import React, { Component } from 'react'
import { APIURL } from '../config/config'
import axios from 'axios'
import {AlertError, AlertSuccess} from './Alert/Alert'
import Switch from 'react-switch'
import Swal from 'sweetalert2'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

class SettingCarColor extends Component{
  constructor (props) {
    super(props);

    this.state = {
      actionType: "บันทึก",
      car_colors: [],
      car_color_name: '',
      car_color_id: '',
    }

    this.loadCarColorData = this.loadCarColorData.bind(this);
    this.saveCarColor = this.saveCarColor.bind(this);
    this.deleteCarColor = this.deleteCarColor.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.onChangeSwitch = this.onChangeSwitch.bind(this);
    this.setEditForm = this.setEditForm.bind(this);

  }

  componentDidMount () {
    this.loadCarColorData()
  }

  loadCarColorData () {
    axios.get(APIURL + '/car_color/')
        .then(res => {
          this.setState({car_colors: res.data})
        }).catch(error => {
      this.setState({car_colors: []})
    })
  }

  deleteCarColor(car_color){
    let car_color_id = car_color.car_color_id
    let car_color_name = car_color.car_color_name
    let data = {
      car_color_id: car_color_id,
      status: 'delete'
    }
    Swal.fire({
      title: 'ยืนยันการลบ สี'+car_color_name+' ?',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        let url = APIURL+'/car_color/color_status'
        axios.post(url, data).then(res => {
          if(res.data === 'success') {
            AlertSuccess('ลบข้อมูลแล้ว')
            this.loadCarColorData()
          }
        })
      }
    });
  }

  saveCarColor() {
    let actionType = this.state.actionType
    let car_color_name = this.state.car_color_name

    let data= {
      car_color_name
    }

    if(actionType === "บันทึก") {
      let url = APIURL+'/car_color'
      axios.post(url, data).then(res => {
        if(res.data === 'success') {
          AlertSuccess('บันทึกข้อมูลแล้ว')
          this.clearForm()
          this.loadCarColorData()
        }
      })
    } else if (actionType === "แก้ไข") {
      let car_color_id = this.state.car_color_id
      data = {...data, car_color_id: car_color_id }
      let url = APIURL+'/car_color/'+car_color_id
      axios.put(url, data).then(res => {
        if(res.data === 'success') {
          AlertSuccess('แก้ไขข้อมูลแล้ว')
          this.clearForm()
          this.loadCarColorData()
        }
      })
    }
  }

  setEditForm(car_color) {
    this.setState({
      actionType: 'แก้ไข',
      car_color_name: car_color.car_color_name,
      car_color_id: car_color.car_color_id,
    })
  }

  clearForm() {
    this.setState({
      actionType: "บันทึก",
      car_color_name: '',
      car_color_id: '',
    })
  }

  onChangeSwitch(checked, car_color){
    let item_update = car_color
    let car_colors = this.state.car_colors
    let item_index = this.state.car_colors.findIndex(data => data.car_color_id=== car_color.car_color_id)

    if(checked === false){
      item_update.status = 'hidden'
    } else {
      item_update.status = 'show'
    }

    let data = {
      car_color_id: item_update.car_color_id,
      status: item_update.status
    }

    let url = APIURL+'/car_color/color_status'
    axios.post(url, data).then(res => {

    })

    car_colors[item_index] = item_update
    this.setState({
      car_colors: car_colors
    })
  }

  render () {

    return (
      <div className="row">
        <div className="col-sm-7">
          <div className="card card-accent-info">
            <div className="card-header">
              <strong className="text-title">
                สีรถ
              </strong>
            </div>
            <div className="card-block" style={styles.overflowY}>
              <table className="table table-striped table-responsive">
                <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th className="text-center">ชื่อสีรถ</th>
                  <th className="text-center">การใช้งาน</th>
                  <th className="text-center">จัดการ</th>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.car_colors.map((car_color, index) => (
                    <tr key={index}>
                      <td style={{width: 10}}>{ index+1 }</td>
                      <td className="text-nowrap" style={{width: 80}}>{ car_color.car_color_name }</td>
                      <td className="text-nowrap text-center" style={{width: 50}}>

                        { car_color.status === 'show' ? 'ใช้งาน' : 'ไม่ใช้งาน' }

                        <br/>

                        <Switch height = {20} width ={48}
                                onChange={(checked) => this.onChangeSwitch(checked, car_color)}
                                checked={car_color.status === 'show'}
                        />
                      </td>

                      <td className="text-center" style={{width: 120}}>
                        <button type="button"
                                className="btn btn-sm btn-warning btn-md-width mr-2 mb-2"
                                onClick={() => this.setEditForm(car_color)}
                        >
                          <i className="fa fa-edit"/> แก้ไข
                        </button>
                        <button type="button"
                                className="btn btn-sm btn-danger btn-md-width mr-2 mb-2"
                                onClick={() => this.deleteCarColor(car_color)}
                        >
                          <i className="fa fa-remove"/> ลบ
                        </button>
                      </td>
                    </tr>
                  ))
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="col-sm-5">
          <div className="card card-accent-success">
            <div className="card-header card-customer">
              <strong className="text-title">เพิ่ม/แก้ไข สีรถ</strong>
            </div>

            <div className="card-block">
              <div className="form-group">
                <label htmlFor="car_structure_name">ชื่อสีรถ</label>
                <input
                  value={this.state.car_color_name}
                  type="text"
                  className="form-control"
                  name="car_color_name"
                  placeholder="ชื่อสีรถ"
                  style={styles.inputSearch}
                  onChange={(e) => {
                    this.setState({
                      car_color_name: e.target.value
                    })
                  }}
                />
              </div>
            </div>

            <div className="card-footer text-right">
              <button type="button"
                      className="btn btn-sm btn-danger mr-2"
                      style={styles.button}
                      onClick={()=> this.clearForm()}
              >
                <i className="fa fa-refresh"/> ยกเลิก
              </button>

              <button type="button"
                      className={this.state.actionType==="แก้ไข"?  "btn btn-sm btn-warning" : "btn btn-sm btn-primary"}
                      style={styles.button}
                      onClick={() => {
                        this.saveCarColor()
                      }}
              >
                <i className={this.state.actionType === "แก้ไข" ? "fa fa-edit" : "fa fa-save"}/> {this.state.actionType}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  button: {
    borderRadius:5,
    height: 36,
  },
  inputSearch: {
    borderRadius:5,
  },
  overflowY: {
    overflow: 'scroll',
  }
}

export default SettingCarColor
