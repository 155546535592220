import React, { Component } from 'react'
import Loading from '../Loading'
import { APIURL } from '../../config/config'
import axios from 'axios'
import { MonthUtil } from '../../utils/monthUtil'
import { th } from 'date-fns/locale'
import BDatePicker from '../BDatePicker'
import { isNumber } from 'chart.js/helpers'
import { AlertError, AlertSuccess } from '../Alert/Alert'
import { DateUtil } from '../../utils/dateUtil'

class TrackInformationCarSell extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      is_first_load: true,
      month_year_list: [],
      car_sells: []
    }
    this.loadCarSellMonthList = this.loadCarSellMonthList.bind(this)
    this.sellMonthYearChange = this.sellMonthYearChange.bind(this)
    this.loadCarSellData = this.loadCarSellData.bind(this)
    this.onChangeDate = this.onChangeDate.bind(this)
    this.onClickSave = this.onClickSave.bind(this)
  }

  componentDidMount () {
     this.loadCarSellMonthList()
  }

  loadCarSellMonthList() {
    let uri = APIURL + '/car_sell/list/month'
    axios.get(uri)
      .then(res => {
        if( res.data.length > 0) {
          let months = res.data
          let month_year_list =  months.map(month => {
            let month_list_thai = MonthUtil.convertMonthYear(month.month_list)
            return {
              ...month,
              month_list_thai: month_list_thai
            }
          });

          this.setState({
            month_year_list: month_year_list
          }, () => {
            if (this.state.is_first_load) {
              this.loadCarSellData('no_send_book')
            }
          })
        }
      }).catch(error => {

    })
  }

  sellMonthYearChange(e) {
    let value = e.target.value
    this.setState({
      sell_month_year: value
    }, () => this.loadCarSellData(value) )

  }

  loadCarSellData(data) {
    let typeLoad = ''
    let start_date = ''
    let end_date = ''

    if(data === null) {
      typeLoad ='no_send_book'
    } else if (data === 'no_send_book') {
      typeLoad ='no_send_book'
    } else {
      typeLoad = 'date'
    }

    let month_list_thai = ''
    if(typeLoad === 'no_send_book') {
      start_date = 'no_send_book'
      end_date = 'no_send_book'
      month_list_thai = 'no_send_book'
    } else {
      const month_year_list = this.state.month_year_list.find(month_year => month_year.month_list === data)
      start_date = month_year_list.start_date
      end_date = month_year_list.end_date
      month_list_thai = month_year_list.month_list_thai
    }

    let uri = APIURL + '/car_sell/trackinformation/'+start_date+'/'+end_date
    axios.get(uri).then(res => {

      let data = res.data.map(car => {
        return {...car, car_edit: 1}
      })

      this.setState({
        car_sells: data
      })
    }).catch(error => {
      console.log(error)
    })

  }

  onChangeDate(carSell, name, date) {
    let car_sell_id = carSell.car_sell_id
    let value = date === '1970-01-01' ? null : date

    let car_list = this.state.car_sells.map(car => car.car_sell_id === car_sell_id ? {...car, [name]: value, car_edit: 2 } : car )

    this.setState({
      car_sells: car_list
    });

  }

  onChangeInputRow(carSelect, index, event) {

    let car_sell_id = carSelect.car_sell_id
    let name = event.target.name
    let value = event.target.value

    let car_list = this.state.car_sells.map(car => car.car_sell_id === car_sell_id ? {...car, [name]: value, car_edit: 2 } : car )

    this.setState({
      car_sells: car_list
    });

  }

  onClickSave(car_sell, index,) {

      let data = {
        car_sell_id: car_sell.car_sell_id,
        receipt_transfer_document_date: car_sell.receipt_transfer_document_date,
        transfer_document_status_text: car_sell.transfer_document_status_text,
        transfer_document_send_date: car_sell.transfer_document_send_date,
      }

    this.setState({
      isLoading: true
    }, () => {
      let car_sell_id = car_sell.car_sell_id
      let url = APIURL+'/car_sell/trackinformation/'+car_sell_id
      axios.put(url, data).then(res => {
        if(res.status === 200) {
          let car_list = this.state.car_sells.map(car => car.car_sell_id === car_sell_id ? {...car, car_edit: 1,} : car )
          this.setState({
            car_sells: car_list,
            isLoading: false
          }, () => {
            AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว')
            this.loadCarSellData('no_send_book')
          });

        }
      })
        .catch(error => {
          AlertError("พบข้อผิดพลาด : " + error)
          this.setState({
            isLoading: false
          })
        })

    })
  }


  render () {
    const { car_sells } = this.state
    const isDateFill = this.props.isDateFill

    let month_year_list_option = this.state.month_year_list.map((month_year, index) => (
      <option key={index} value={month_year.month_list}>{month_year.month_list_thai}</option>
    ))

    let car_sell_tr = car_sells.map((car_sell, index) => {

      let license_plate_new = car_sell.car_license_plate_new+' : '+car_sell.province_name_new
      let license_plate_old = car_sell.car_license_plate_old+' : '+car_sell.province_name_old
      // let car_brand_name = car_sell.car_brand_name
      // let car_model_name = car_sell.car_model_name
      let car_sell_date = DateUtil.convertCCtoDDMMYYYY(car_sell.car_sell_date)

      let car_issue_date = car_sell.car_issue_date !== null ? DateUtil.convertCCtoDDMMYYYY(car_sell.car_issue_date) : '-'

      return <tr key={index}>
        <td className="text-center" >
          {
            car_sell.car_edit === 2 ?
              <button
                className={car_sell.car_edit === 1 ? "btn btn-sm btn-success" : (car_sell.car_edit === 2) ? "btn btn-sm btn-warning" : "btn btn-sm " }
                disabled={car_sell.car_edit===3}
                onClick={() => this.onClickSave(car_sell, index)}
              > บันทึก </button>
              :  index+1
          }
        </td>
        <td>{ car_sell_date }</td>
        <td>{ car_issue_date }</td>
        <td>{car_sell.book_status_text}</td>
        <td>{car_sell.liciense_status_name}</td>
        <td style={{width: 90}}>
          <BDatePicker
            isDateFill={isDateFill}
            style={styles.input}
            name={'receipt_transfer_document_date'}
            value={this.state.car_sells[index].receipt_transfer_document_date}
            label={''}
            onUpdateDate={(buddhist_date, christ_date) => {
              // receipt_transfer_document_date
              this.onChangeDate(car_sell, 'receipt_transfer_document_date', christ_date)
            }}
          />
        </td>
        <td>
          <input
            type="text"
            name="transfer_document_status_text"
            style={styles.input}
            maxLength={50}
            value={this.state.car_sells[index].transfer_document_status_text}
            onChange={(event)=> this.onChangeInputRow(car_sell, index, event)}
          />
        </td>
        <td  style={{width: 90}}>
          <BDatePicker
            isDateFill={isDateFill}
            style={styles.input}
            name={'transfer_document_send_date'}
            value={this.state.car_sells[index].transfer_document_send_date}
            label={''}
            onUpdateDate={(buddhist_date, christ_date) => {
              // receipt_transfer_document_date
              this.onChangeDate(car_sell, 'transfer_document_send_date', christ_date)
            }}
          />
        </td>
        <td> {license_plate_new} </td>
        <td> {license_plate_old} </td>
        <td> {car_sell.car_brand_name} </td>
        <td> {car_sell.car_model_name} </td>
        <td> {car_sell.bank_finance_name_new} </td>
        <td> {car_sell.sale_name} </td>
      </tr>
    })

    return (
      <div className="row">

        <Loading isLoading={this.state.isLoading} />

        <div className="col-sm-12 col-md-12">
          <div className="card card-accent-primary">
            <div className="card-header card-customer">
              <strong> ติดตามข้อมูลการขาย จำนวน {car_sells.length} คัน</strong>
            </div>
            <div className="card-block">
              <div className="row mb-2 pl-3">
                <div style={{paddingTop: 6, marginRight: 6}} >เลือก</div>
                <div>
                  <select
                    className="form-control"
                    name="sell_month_year"
                    onChange={(e) => this.sellMonthYearChange(e)}
                  >
                    <option value="no_send_book">เฉพาะลูกค้าที่ยังไม่ส่งเล่ม</option>
                    {month_year_list_option}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-12 tableFixHead">
                    <table className="table table-striped table-responsive" >
                      <thead>
                      <tr>
                        <th className="text-center" style={{width: 28}}>ลำดับ</th>
                        <th style={{width: 100}} className=" text-nowrap">ว.ด.ป ที่ขาย</th>
                        <th style={{width: 100}} className=" text-nowrap">วันที่ออกรถ/ปล่อยรถ</th>
                        <th style={{width: 100}} className=" text-nowrap">เล่ม</th>
                        <th style={{width: 100}} className=" text-nowrap">สถานะเล่ม</th>
                        <th style={{width: 100}} className=" text-nowrap">ว.ด.ป รับเอกสารโอน</th>
                        <th style={{width: 100}} className=" text-nowrap">สถานะจัดส่งลูกค้า</th>
                        <th style={{width: 100}} className=" text-nowrap">ว.ด.ป ส่งเอกสารลูกค้า</th>
                        <th style={{width: 100}} className=" text-nowrap">ทะเบียนใหม่</th>
                        <th style={{width: 100}} className=" text-nowrap">ทะเบียนเดิม</th>
                        <th style={{width: 100}} className=" text-nowrap">ยี่ห้อ</th>
                        <th style={{width: 100}} className=" text-nowrap">รุ่น</th>
                        <th style={{width: 100}} className=" text-nowrap">ไฟแนนซ์</th>
                        <th style={{width: 100}} className=" text-nowrap">ขายโดย</th>
                      </tr>
                      </thead>
                      <tbody>
                      {car_sell_tr}
                      </tbody>
                    </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

const styles = {
  input: {
    width: 100,
  }
}

export default TrackInformationCarSell
