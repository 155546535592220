import React, { Component } from 'react';
import { th } from 'date-fns/locale';
import axios from 'axios';
import { APIURL, CLOUDFRONT_URL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL } from '../../config/config';
import Loading from '../Loading';
import { DateUtil } from '../../utils/dateUtil';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { NumberUtil } from '../../utils/number-util';
import { isNumber } from 'chart.js/helpers';
import { AlertError, AlertSuccess } from '../Alert/Alert';
import './table.css';

class TrackInformationCarIn extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      cars: [],
      car_status: [],
      car_parking_list: [],
      book_status_text_list: [],
      orders: [
        {order_id: 'car_in_desc', order_name: 'วันที่ซื้อรถเข้า : ใหม่ไปเก่า'},
        {order_id: 'car_in_asc', order_name: 'วันที่ซื้อรถเข้า : เก่าไปใหม่'},
        {order_id: 'car_brand_desc', order_name: 'ยี่ห้อ : มากไปน้อย'},
        {order_id: 'car_brand_asc', order_name: 'ยี่ห้อ : น้อยไปมาก'},
        {order_id: 'car_date_tax_desc', order_name: 'วันครบกำหนดภาษี : มากไปน้อย'},
        {order_id: 'car_date_tax_asc', order_name: 'วันครบกำหนดภาษี : น้อยไปมาก'},
        {order_id: 'year_desc', order_name: 'ปีรถ : ใหม่ไปเก่า'},
        {order_id: 'year_asc', order_name: 'ปีรถ : เก่าไปใหม่'},
        {order_id: 'overdue_date_tax_desc', order_name: 'ภาษีขาด : มากไปน้อย'},
        {order_id: 'overdue_date_tax_asc', order_name: 'ภาษีขาด : น้อยไปมาก'},
      ],
      width: 0,
      height: 0,
      order_by: '',
      park_location: '',
      car_status_id: '',
      book_status_text: ''
    }

    this.loadCarData = this.loadCarData.bind(this);
    this.onChangeInputRowLeft = this.onChangeInputRowLeft.bind(this);
    this.onChangeInputRowRight = this.onChangeInputRowRight.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.loadCarStatusData = this.loadCarStatusData.bind(this);
    this.getDataCarTrackInformationWithCriteria = this.getDataCarTrackInformationWithCriteria.bind(this);
  }

  componentDidMount () {
    this.loadCarParking()
    this.loadCarStatusData()
    this.loadCarData()

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount () {
    this.mounted = false;
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  loadCarStatusData () {
    axios.get(APIURL + '/car_status/new')
      .then(res => {
        this.setState({car_status: res.data})
      }).catch(error => {
      this.setState({car_status: []})
    })
  }

  loadCarData() {
      let uri = APIURL + '/car'

     this.setState({
       isLoading: true
     },() => {
       axios.get(uri).then(res => {

         let cars = res.data
         let data = cars.map(car => {
           return {...car, car_edit: 1, edit_left: 1 ,edit_right: 1}
         });

         // book_status_text
         let _ = require('lodash');
         let bookStatusTextUniq = _.uniqBy(cars, 'book_status_text');
         // let bookStatusTextFilter = _.filter(
         //   bookStatusTextUniq, function (o) {
         //     return o.book_status_text !== '';
         //   }
         // );
         let bookStatusSorted = _.orderBy(bookStatusTextUniq, ['book_status_text'], ['asc']);

         let bookStatusTextArrs = _.map(bookStatusSorted, 'book_status_text');
         // console.log(bookStatusTextArrs)

         this.setState({
           cars: data,
           book_status_text_list: bookStatusTextArrs,
           isLoading: false
         });
       })
     });
  }

  getDataCarTrackInformationWithCriteria() {
    let order_by = this.state.order_by
    let park_location = this.state.park_location
    let car_status_id = this.state.car_status_id
    let book_status_text = this.state.book_status_text
    let data = {
      order_by,
      park_location,
      car_status_id,
      book_status_text
    }
    console.log(data)
  }

  onChangeInputRowLeft(carSelect, index, event) {
    let car_id = carSelect.car_id
    let name = event.target.name
    let value = event.target.value

    let car_list = this.state.cars.map(car => car.car_id === car_id ? {...car, [name]: value, car_edit: 2, edit_left: 2 } : car )

    this.setState({
      cars: car_list
    });

  }

  onChangeInputRowRight(carSelect, index, event) {

    let car_id = carSelect.car_id
    let name = event.target.name
    let value = event.target.value
    let number_fields = [
      'finance_average_price',
      'monthly_installment_48',
      'monthly_installment_60',
      'monthly_installment_72',
      'monthly_installment_84',
      'monthly_installment_96'
    ]

    if (number_fields.includes(name)) {
      // check is number
      if(!isNumber(value)) {
        AlertError('กรุณากรอกตัวเลข')
        // let car_list = this.state.cars.map(car => car.car_id === car_id ? {...car, [name]: value, car_edit: 2 } : car )
        //
        // this.setState({
        //   cars: car_list
        // });
        return
      }
    }

    let car_list = this.state.cars.map(car => car.car_id === car_id ? {...car, [name]: value, car_edit: 2, edit_right: 2 } : car )

    this.setState({
      cars: car_list
    });

  }

  onClickSave(car, index,) {
    let data = {
      park_location: car.park_location,
      repair_status_text: car.repair_status_text,
      sell_status_text: car.sell_status_text,
      book_status_text: car.book_status_text,
      // fb_page: car.fb_page,
      // finance_average_price: car.finance_average_price,
      // monthly_installment_48: car.monthly_installment_48,
      // monthly_installment_60: car.monthly_installment_60,
      // monthly_installment_72: car.monthly_installment_72,
      // monthly_installment_84: car.monthly_installment_84,
      // monthly_installment_96: car.monthly_installment_96,
      car_id: car.car_id,
    }


    let car_id = car.car_id
    this.setState({
      isLoading: true
    }, () => {

      let url = APIURL+'/car/trackinformation/'+car_id
      axios.put(url, data).then(res => {
        if(res.status === 200) {
          let car_list = this.state.cars.map(car => car.car_id === car_id ? {...car, car_edit: 1, edit_left: 1 ,edit_right: 1 } : car )
          this.setState({
            cars: car_list,
            isLoading: false
          }, () => {
            AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว')
          });

        }
      })
        .catch(error => {
          AlertError("พบข้อผิดพลาด : " + error)
          this.setState({
            isLoading: false
          })
        })

    })
  }

  loadCarParking() {
    let url = APIURL+'/car_parking'
    axios.get(url).then(res => {
      let data = res.data
      this.setState({
        car_parking_list: data
      })
    })
  }

  render () {

    let {
      book_status_text_list,
      cars,
      width,
      car_parking_list,
      car_status,
      orders
    } = this.state

    // let cars = this.state.cars
    // let width = this.state.width

    let width_box = 100

    if (width <= 980){
      width_box = 80
    }

    let carParkingOptions = car_parking_list.map((parking, index) => {
      return <option value={parking.car_parking_name} key={index}> {parking.car_parking_name} </option>
    })

    let carStatusOptions = car_status.map((status, index) => {
      return <option value={status.car_status_id} key={index}> {status.car_status_name} </option>
    })

    let bookStatusOptions = book_status_text_list.map((book_status_text, index) => {
      let book_status_str = book_status_text === '' ? 'ไม่ระบุ': book_status_text
      let book_status_val = book_status_text === '' ? 'empty': book_status_text
      return <option value={book_status_val} key={index}> {book_status_str} </option>
    })

    let order_list = orders.map((order, index) => {
      return (
        <option key={index} value={order.order_id}>{order.order_name}</option>
      )
    })


    let carList = cars.map((car, index) => {

      let car_date_tax = car.car_date_tax
      let car_date_tax_text = DateUtil.convertShortDateThaiDash(car_date_tax)
      let car_date_in = car.car_date_in
      let car_date_in_text = DateUtil.convertShortDateThaiDash(car_date_in)
      let car_license_plate_new = car.car_license_plate_new
      let car_license_plate_old = car.car_license_plate_old
      let car_brand_name = car.car_brand_name
      let car_model_name = car.car_model_name
      let car_sale_price = NumberUtil.addCommas(car.car_sale_price)

      let date_ago = car_date_tax !== null && car_date_tax !== '0000-00-00' ? DateUtil.carDateTaxOverdue(car_date_tax) : '-'

      // let repair_status = ''
      // let sell_status = ''
      // let book_status = ''
      // let finance_average_price = ''
      // let monthly_installment_4 = ''
      // let monthly_installment_5 = ''
      // let monthly_installment_6 = ''
      // let monthly_installment_7 = ''
      // let monthly_installment_8 = ''

      let image_url = ''
      if (car.car_upload_filename !== null) {
        if (car.car_upload_filename.includes('https')) {
          // image_url = car.car_upload_filename
          let car_upload_key = car.car_upload_key
          image_url = CLOUDFRONT_URL+car_upload_key
        } else {
          image_url = IMAGE_FULL_URL + car.car_upload_filename
        }
      } else {
        image_url = IMAGE_NOPHOTO_URL
      }

      let order = index+1
      return <tr key={index}>
        <td>
          {
            car.edit_left === 2 ?
              <button
                className={car.car_edit === 1 ? "btn btn-sm btn-success" : (car.car_edit === 2) ? "btn btn-sm btn-warning" : "btn btn-sm " }
                disabled={car.car_edit===3}
                onClick={() => this.onClickSave(car, index)}
              > บันทึก </button>
              :  order
          }
        </td>
        <td>
          <select className="form-control"
                  name="park_location"
                  style={{...styles.selectFilter, width: width_box}}
                  value={this.state.cars[index].park_location}
                  onChange={(event)=> this.onChangeInputRowLeft(car, index, event)}
          >
            {carParkingOptions}
          </select>
        </td>
        <td>
          {car.car_status_name}
        </td>
        <td>
           <input
             type="text"
             name="repair_status_text"
             style={styles.input}
             maxLength={50}
             value={this.state.cars[index].repair_status_text}
             onChange={(event)=> this.onChangeInputRowLeft(car, index, event)}
           />
        </td>
        {/*<td>
          <input
            type="text"
            name="sell_status_text"
            style={styles.input}
            maxLength={50}
            value={this.state.cars[index].sell_status_text}
            onChange={(event)=> this.onChangeInputRowLeft(car, index, event)}
          />
        </td>*/}
        <td>
          <input name="book_status_text" style={styles.input}
                 maxLength={50}
                 value={this.state.cars[index].book_status_text}
                 onChange={(event)=> this.onChangeInputRowLeft(car, index, event)}
          />
        </td>
        <td> {car.liciense_status_name}</td>
        <td> {car.buy_car_from}</td>
        <td> {car.branch_name}</td>
        <td>
          {date_ago === '-' ? '-' : (<span className="text-danger">{date_ago}</span>)}
        </td>
        <td> {car_date_tax_text} </td>
        <td> {order} </td>
        <td> {car_date_in_text} </td>
        <td className=" text-nowrap"> ใหม่ : {car_license_plate_new} <br/> เดิม : {car_license_plate_old} </td>
        <td> {car_brand_name} </td>
        <td> {car_model_name} </td>
        <td> {car.car_gear_name} </td>
        <td> {car.car_color_name} </td>
        <td> {car.car_year_name} </td>
        <td>
          <LazyLoadImage src={image_url}
                         width={80}
                         height="auto"
                         effect="blur"
          />
        </td>
        <td>
          <input type="text" name="fb_page" style={styles.input} maxLength={50}
                 value={this.state.cars[index].fb_page}
                 onChange={(event)=> this.onChangeInputRowRight(car, index, event)}
          />
        </td>
        <td>{car_sale_price}</td>

        <td>
          <input
            type="text"
            maxLength={8}
            name="finance_average_price"
            style={styles.input}
            value={this.state.cars[index].finance_average_price}
            onChange={(event)=> this.onChangeInputRowRight(car, index, event)}
          />
        </td>
        <td>
          <input name="monthly_installment_48"
                 maxLength={8}
                 type="text"
                 style={styles.input}
                 value={this.state.cars[index].monthly_installment_48}
                 onChange={(event)=> this.onChangeInputRowRight(car, index, event)}
          />
        </td>
        <td>
          <input
            name="monthly_installment_60"
            style={styles.input}
            type="text"
            maxLength={8}
            value={this.state.cars[index].monthly_installment_60}
            onChange={(event)=> this.onChangeInputRowRight(car, index, event)}
          />
        </td>
        <td>
          <input name="monthly_installment_72"
                 style={styles.input}
                 type="text"
                 maxLength={8}
                 value={this.state.cars[index].monthly_installment_72}
                 onChange={(event)=> this.onChangeInputRowRight(car, index, event)}
          />
        </td>
        <td>
          <input name="monthly_installment_84"
                 style={styles.input}
                 type="text"
                 maxLength={8}
                 value={this.state.cars[index].monthly_installment_84}
                 onChange={(event)=> this.onChangeInputRowRight(car, index, event)}
          />
        </td>
        <td>
          <input name="monthly_installment_96"
                 style={styles.input}
                 type="text"
                 maxLength={8}
                 value={this.state.cars[index].monthly_installment_96}
                 onChange={(event)=> this.onChangeInputRowRight(car, index, event)}
          />
        </td>

        <td className="text-center" >
          {
            car.edit_right === 2 ?
              <button
                className={car.car_edit === 1 ? "btn btn-sm btn-success" : (car.car_edit === 2) ? "btn btn-sm btn-warning" : "btn btn-sm " }
                disabled={car.car_edit===3}
                onClick={() => this.onClickSave(car, index)}
              > บันทึก </button>
              :  <i className="icon-close" />
          }
        </td>

      </tr>
    })



    return (
      <div className="row">
        <Loading isLoading={this.state.isLoading} />

        <div className="col-sm-12 col-md-12">
          <div className="card card-accent-primary">
            <div className="card-header card-customer">
             <strong>ติดตามข้อมูลรถคงเหลือ {cars.length} คัน</strong>
            </div>
            <div className="card-block">
              <div className="row">
                <div className="col-12">

                  <select className="form-control mr-1 mb-1"
                          name="park_location"
                          style={{...styles.selectFilter, width: 120}}
                          value={this.state.park_location}
                          onChange={(e) => {
                            this.setState({park_location: e.target.value})
                          }}
                  >
                    <option value="">สถานที่จอด</option>
                    {carParkingOptions}
                  </select>

                  <select className="form-control mr-1 mb-1"
                          name="park_location"
                          style={{...styles.selectFilter, width: 120}}
                          value={this.state.car_status_id}
                          onChange={(e) => {
                            this.setState({car_status_id: e.target.value})
                          }}
                  >
                    <option value="">สถานะรถ</option>
                    {carStatusOptions}
                  </select>

                  <select className="form-control mr-1 mb-1"
                          name="book_status_text"
                          style={{...styles.selectFilter, width: 120}}
                          value={this.state.book_status_text}
                          onChange={(e) => {
                            this.setState({book_status_text: e.target.value})
                          }}
                  >
                    <option value="">ที่อยู่เล่ม</option>
                    {bookStatusOptions}
                  </select>


                  <select className="form-control mr-1 mb-1"
                          name="order_by"
                          style={{...styles.selectFilter, width: 230}}
                          value={this.state.order_by}
                          onChange={(e) => {
                            this.setState({order_by: e.target.value})
                          }}
                  >
                    <option value="">เรียงตาม</option>
                    {order_list}
                  </select>

                  <button
                    className="btn btn-sm btn-primary mr-1 ml-2"
                    onClick={() => this.getDataCarTrackInformationWithCriteria()}
                    style={styles.buttonHeight}
                  >
                    <i className="icon-search"/>ค้นหา
                  </button>

                </div>
                <div className="col-12 tableFixHead" >
                      <table className="table table-striped table-responsive">
                        <thead>
                        <tr>
                          <th className="text-center th-sticky" >ลำดับ</th>
                          <th style={{width: 150}} className=" text-nowrap th-sticky">สถานที่จอด</th>
                          <th style={{width: 150}} className=" text-nowrap">สถานะรถ</th>
                          <th style={{width: 150}} className=" text-nowrap">สถานะซ่อม</th>
                          {/*<th style={{width: 150}} className=" text-nowrap">สถานะขาย</th>*/}
                          <th style={{width: 150}} className=" text-nowrap text-center">ที่อยู่เล่ม</th>
                          <th style={{width: 150}} className=" text-nowrap">สถานะเล่ม</th>
                          <th style={{width: 150}} className=" text-nowrap">หัวบิล/ซื้อรถจาก</th>
                          <th style={{width: 150}} className=" text-nowrap">เจ้าของ/สาขา</th>
                          <th style={{width: 150}} className=" text-nowrap">ภาษีขาด</th>
                          <th style={{width: 150}} className=" text-nowrap">วันครบกำหนดต่อภาษี</th>
                          <th className="text-center th-sticky" >ลำดับ</th>
                          <th style={{width: 150}} className=" text-nowrap">วันซื้อเข้า</th>
                          <th style={{width: 150}} className=" text-nowrap">ทะเบียน</th>
                          <th style={{width: 150}} className=" text-nowrap">ยี่ห้อ</th>
                          <th style={{width: 150}} className=" text-nowrap">รุ่น</th>
                          <th style={{width: 150}} className=" text-nowrap">เกียร์</th>
                          <th style={{width: 150}} className=" text-nowrap">สี</th>
                          <th style={{width: 150}} className=" text-nowrap">ปี</th>
                          <th style={{width: 150}} className=" text-nowrap">รูป</th>
                          <th style={{width: 150}} className=" text-nowrap">มาร์เก็ต</th>
                          <th style={{width: 150}} className=" text-nowrap">ราคาขาย</th>
                          <th style={{width: 150}} className=" text-nowrap">ราคากลาง</th>
                          <th style={{width: 150}} className=" text-nowrap">ผ่อน 4 ปี (48 งวด)</th>
                          <th style={{width: 150}} className=" text-nowrap">ผ่อน 5 ปี (60 งวด)</th>
                          <th style={{width: 150}} className=" text-nowrap">ผ่อน 6 ปี (72 งวด)</th>
                          <th style={{width: 150}} className=" text-nowrap">ผ่อน 7 ปี (84 งวด)</th>
                          <th style={{width: 150}} className=" text-nowrap">ผ่อน 8 ปี (96 งวด)</th>
                          <th style={{width: 150}} className=" text-nowrap">จัดการ</th>
                        </tr>
                        </thead>
                        <tbody>
                        {carList}
                        </tbody>
                      </table>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  input: {
    width: 80,
  },
  searchCar: {
    marginLeft: 0,
    marginTop: 5,
    marginBottom: -8,
    width: 180,
    borderRadius:5,
    display: 'inline'
  },
  buttonHeight: {
    height: 36,
    paddingTop: 0,
    paddingBottom:0,
    borderRadius:5,
    display: 'inline',
    marginTop: -2,
    marginBottom: 0,
  },
  selectFilter: {
    marginTop: 5,
    marginBottom: -8,
    borderRadius:5,
    display: 'inline'
  },
  overflowY: {
    overflow: 'scroll',
  }
}

export default TrackInformationCarIn
