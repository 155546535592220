import React , { Component } from 'react';
import { connect } from 'react-redux'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames';
import TrackInformationCarIn from './TrackInformationCarIn'
import TrackInformationCarSell from './TrackInformationCarSell'
import FinanceCriteria from './FinanceCriteria'
import axios from 'axios'
import { APIURL } from '../../config/config'

class TrackInformationTabs extends Component {
  constructor (props) {
    super(props);
    this.state = {
      activeTab: '1',
      company_setting: null,
      is_date_fill: 0,
    }

    this.toggle = this.toggle.bind(this);
    this.loadCompanySetting = this.loadCompanySetting.bind(this);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  async componentDidMount() {
    await this.loadCompanySetting();
  }

  async loadCompanySetting() {
    await axios.get(APIURL + '/company_setting/1').then(res => {
      let data = res.data
      let company_setting = data.company_setting
      const is_date_fill = company_setting.is_date_fill;
      this.setState({
        company_setting: company_setting,
        is_date_fill: is_date_fill
      })
    })
  }

  render() {

    return (
      <div className="col-md-12 mb-4">

        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              <i className="icon-compass"/> ติดตามรถเข้า
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              <i className="icon-basket"/> ติดตามรถขายแล้ว
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '3' })}
              onClick={() => { this.toggle('3'); }}
            >
              <i className="icon-anchor"/> เงื่อนไขไฟแนนซ์
            </NavLink>
          </NavItem>
        </Nav>

          <TabContent activeTab={this.state.activeTab}>

            <TabPane tabId="1">
              {
                this.state.activeTab === '1' && <TrackInformationCarIn />
              }
            </TabPane>

            <TabPane tabId="2">
              {
                this.state.activeTab === '2' &&
                <TrackInformationCarSell
                  isDateFill = {this.state.is_date_fill}
                />
              }
            </TabPane>

            <TabPane tabId="3">
              <FinanceCriteria />
            </TabPane>

          </TabContent>


      </div>
    )
  }

}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(TrackInformationTabs)
