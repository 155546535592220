import React, { Component } from 'react';
import * as moment from 'moment';
import 'moment/locale/th';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DateUtil } from '../../utils/dateUtil';
import { NumberUtil } from '../../utils/number-util';
import stringUtil from '../../utils/stringUtil'

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew-Regular.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

class DeliverCarOkPDF extends Component {
  constructor (props) {
    super(props);
    this.state = {

    }
    this.makePDF = this.makePDF.bind(this)
  }

  componentDidMount () {
    this.makePDF()
  }

  makePDF() {
    const {
      car_sell,
      company_setting,
      profile,
      car_sell_pay_list
    } = this.props

    // let cardIdImage64 = this.props.cardIdImage64
    // let carDeliverImage64 = this.props.carDeliverImage64

    const company_name = company_setting.company_name
    const branch_name = car_sell.branch_name
    const sale_name = car_sell.sale_name
    const profile_from = profile===null ? '-' : profile.profile_from

    // tableFinance
    const real_sell_price = NumberUtil.addCommasZeroInt(car_sell.real_sell_price)
    const rate_finance = NumberUtil.addCommasZeroInt(car_sell.rate_finance)
    const car_excess_amount = NumberUtil.addCommasZeroInt(car_sell.car_excess_amount) // ยอดจัดเกิน
    const sum_down = NumberUtil.addCommasZeroInt(car_sell.sum_down) // ยอดจัดเกิน
    // ค่างวดรถล่วงหน้า
    const pay_advance_finance = NumberUtil.addCommasZeroInt(car_sell.pay_advance_finance)
    // ส่วนต่าง
    const diff = Number(car_sell.real_sell_price) -Number(car_sell.rate_finance)
    const diff_price = NumberUtil.addCommasZeroInt(diff)

    // car_sell_list_pay_id = 1 เงินจอง
    let car_sell_pay01 = car_sell_pay_list.filter(car_sell_pay => car_sell_pay.car_sell_list_pay_id === 1);
    let reservation_money = car_sell_pay01.length === 0 ? 0 : car_sell_pay01[0].car_sell_pay_count

    // car_sell_list_pay_id = 2 เงินดาวน์
    let car_sell_pay02 = car_sell_pay_list.filter(car_sell_pay => car_sell_pay.car_sell_list_pay_id === 2);
    let down_money = car_sell_pay02.length === 0 ? 0 : car_sell_pay02[0].car_sell_pay_count

    // car_sell_list_pay_id = 3 เงินมัดจำ  cash pledge
    let car_sell_pay03 = car_sell_pay_list.filter(car_sell_pay => car_sell_pay.car_sell_list_pay_id === 3);
    let pledge_money = car_sell_pay03.length === 0 ? 0 : car_sell_pay03[0].car_sell_pay_count

    const insurance_price = NumberUtil.addCommasZeroInt(car_sell.insurance_price)

    const down_money_pad = stringUtil.addSpacePaddingRightLeft(down_money, 30)
    const pay_advance_finance_pad = stringUtil.addSpacePaddingRightLeft(pay_advance_finance, 30)
    const insurance_price_pad = stringUtil.addSpacePaddingRightLeft(insurance_price, 30)
    const reservation_money_pad = stringUtil.addSpacePaddingRightLeft(reservation_money, 30)
    // const other_pad = stringUtil.addSpacePaddingRightLeft('.', 30)

    const customer_name = car_sell.customer_name + ' ' + car_sell.customer_lastname
    const customer_card_id = car_sell.customer_card_id === null ? '-' : car_sell.customer_card_id
    const customer_mobile = car_sell.customer_mobile

    const car_year_name = car_sell.car_year_name
    const car_brand_name = car_sell.car_brand_name
    const car_model = car_sell.car_model_name
    const car_name = car_brand_name+' '+car_model

    let car_license_plate = car_sell.car_license_plate_new !== '' &&  car_sell.car_license_plate_new !==  car_sell.car_license_plate_old ? car_sell.car_license_plate_new : car_sell.car_license_plate_old
    let province_name = car_sell.car_license_plate_new !== '' &&  car_sell.car_license_plate_new !==  car_sell.car_license_plate_old ? car_sell.province_name_new : car_sell.province_name_old

    let car_license = car_license_plate+' '+province_name
    let bank_finance_name = car_sell.bank_finance_name_new
    let first_installment = car_sell.first_installment === '0000-00-00' || car_sell.first_installment === null ? '-' : moment(car_sell.first_installment).format('YYYY-MM-DD')
    let duration_period_name = car_sell.duration_period_name
    let monthly_installment = NumberUtil.addCommasZeroInt(car_sell.monthly_installment)
    let insurance_pa = NumberUtil.addCommasZeroInt(car_sell.insurance_pa)
    let monthly_installment_with_pa = Number(car_sell.insurance_pa) + Number(car_sell.monthly_installment)
    let insurance_type_name = car_sell.insurance_type_name
    let insurance_company_name = car_sell.insurance_company_name
    let agent_fullname = car_sell.agent_fullname === null ? '-' : car_sell.agent_fullname
    let agent_mobile = car_sell.agent_mobile === null ? '-' : car_sell.agent_mobile
    let car_external_commission = NumberUtil.addCommasZeroInt(car_sell.car_external_commission)
    // ส่วนลดเงินออกรถ
    let total_outof_discount = NumberUtil.addCommasZeroInt(car_sell.total_outof_discount)

    //  "insurance_pa_buy": "PA ซื้อเอง",
    //  "first_installment": "0000-00-00",
    // "agent_fullname": null,
    //     "agent_mobile": null,

    let company_name_pad = stringUtil.addSpacePaddingFill(company_name, 34)
    let branch_name_pad = stringUtil.addSpacePaddingFill(branch_name, 30)
    let sale_name_pad = stringUtil.addSpacePaddingFill(sale_name, 30)
    let profile_from_pad = stringUtil.addSpacePaddingFill(profile_from, 26)

    // let customer_name_pad = stringUtil.addSpacePaddingFill(customer_name, 34)
    // let customer_card_pad = stringUtil.addSpacePaddingFill(customer_card_id, 34)
    // let customer_mobile_pad = stringUtil.addSpacePaddingFill(customer_mobile, 34)
    let car_name_pad = stringUtil.addSpacePaddingFill(car_name, 40)
    let car_license_pad = stringUtil.addSpacePaddingFill(car_license, 40)
    // let bank_finance_name_pad = stringUtil.addSpacePaddingFill(bank_finance_name, 40)

    let tableTent = {
      table: {
        widths: [ '100%' ],
        body: [
          [
            {
              columns: [
                { width: 72, style: 'normalLeft', text: 'เต้นท์(ประเภท)',},
                { width: '*', style: ['normalLeft', 'bottomLine'], text: company_name_pad,},
                { width: 36, style: 'normalLeft', text: 'สาขา',},
                { width: 80, style: ['normalLeft', 'bottomLine'], text: branch_name_pad,},
                { width: 52, style: 'normalLeft', text: 'ลูกค้าจาก',},
                { width: 80, style: ['normalLeft', 'bottomLine'], text: profile_from_pad,},
                { width: 38, style: 'normalLeft', text: 'ผู้ขาย',},
                { width: 80, style: ['normalLeft', 'bottomLine'], text: sale_name_pad,},
              ],
              alignment: 'left',
              columnGap: 0
            },
          ]
        ]
      }
    };

    //  margin: [0, 0, 0, 0]
    let tbFWidth =  parseFloat(String(100/9)).toFixed(2)+'%';

    let tableFinance = {
      table: {
        widths: [ tbFWidth,tbFWidth,tbFWidth,tbFWidth,tbFWidth,tbFWidth,tbFWidth,tbFWidth,tbFWidth ],
        body: [
            [
              {text: 'ราคา', style: 'headerTable',},
              {text: 'ส่วนต่าง', style: 'headerTable',},
              {text: 'โปรโมชั่น', style: 'headerTable',},
              {text: 'ยอดจัด', style: 'headerTable',},
              {text: 'จัดเกิน', style: 'headerTable',},
              {text: 'เงินดาวน์', style: 'headerTable',},
              {text: 'เงินจอง', style: 'headerTable',},
              {text: 'รวมส่วนลด', style: 'headerTable',},
              {text: 'ขายสุทธิ', style: 'headerTable',},
            ],
           [
             {text: real_sell_price, style: 'normalCenter',},
             {text: diff_price, style: 'normalCenter',},
             {text: '', style: 'normalCenter',},
             {text: rate_finance, style: 'normalCenter',},
             {text: car_excess_amount, style: 'normalCenter',},
             {text: sum_down, style: 'normalCenter',},
             {text: reservation_money, style: 'normalCenter',},
             {text: total_outof_discount, style: 'normalCenter',},
             {text: real_sell_price, style: 'normalCenter',},
           ]
        ]
      },
      margin: [0, 4, 0, 0]
    }

    let tableCarSell01 = {
      table: {
        widths: ['100%'],
        body: [
            [
              {text: 'ทะเบียน', style: 'headerTable', border: [false, false, false, false], margin: [0, 0, 0, 0]},
            ],
            [
              {text: car_license_pad, style: 'headerTable', border: [false, false, false, true], margin: [0, -8, 0, 0]},
            ],
            [
              {text: 'ยี่ห้อ', style: 'headerTable', border: [false, false, false, false], margin: [0, 0, 0, 0]},
            ],
            [
              {text: car_brand_name, style: 'headerTable', border: [false, false, false, true], margin: [0, -8, 0, 0]},
            ],
            [
              {text: 'รุ่น', style: 'headerTable', border: [false, false, false, false], margin: [0, 0, 0, 0]},
            ],
            [
              {text: car_model, style: 'headerTable', border: [false, false, false, true], margin: [0, -8, 0, 0]},
            ],
            [
              {text: 'ปี', style: 'headerTable', border: [false, false, false, false], margin: [0, 0, 0, 0]},
            ],
            [
              {text: car_year_name, style: 'headerTable', border: [false, false, false, true], margin: [0, -8, 0, 0]},
            ],
        ]
      }
    }

    let tableCarSell02 = {
      table: {
        widths: ['100%'],
        body: [
          [
            {text: 'บริษัทไฟแนนท์', style: 'headerTable', border: [false, false, false, false], margin: [0, 0, 0, 0]},
          ],
          [
            {text: bank_finance_name, style: 'headerTable', border: [false, false, false, true], margin: [0, 0, 0, 0]},
          ],
          [
            {text: 'วันที่เริ่มค่างวด', style: 'headerTable', border: [false, false, false, false], margin: [0, 6, 0, 0]},
          ],
          [
            {text: first_installment, style: 'headerTable', border: [false, false, false, true], margin: [0, -6, 0, 0]},
          ],
          [
            {text: 'จำนวนงวด', style: 'headerTable', border: [false, false, false, false], margin: [0, 3, 0, 0]},
          ],
          [
            {text: duration_period_name, style: 'headerTable', border: [false, false, false, true], margin: [0, -6, 0, 0]},
          ],
        ]
      }
    };

    let tableCarSell03 = {
      table: {
        widths: ['100%'],
        body: [
          [
            {text: 'ยอดค่างวด', style: 'headerTable', border: [false, false, false, false], margin: [0, 0, 0, 0]},
          ],
          [
            {text: monthly_installment, style: 'headerTable', border: [false, false, false, true], margin: [0, 0, 0, 0]},
          ],
          [
            {text: 'ประกันชีวิต', style: 'headerTable', border: [false, false, false, false], margin: [0, 6, 0, 0]},
          ],
          [
            {text: insurance_pa, style: 'headerTable', border: [false, false, false, true], margin: [0, -6, 0, 0]},
          ],
          [
            {text: 'รวมค่างวด', style: 'headerTable', border: [false, false, false, false], margin: [0, 3, 0, 0]},
          ],
          [
            {text: monthly_installment_with_pa, style: 'headerTable', border: [false, false, false, true], margin: [0, -6, 0, 0]},
          ],
        ]
      }
    };

    let tableCarSell04 = {
      table: {
        widths: ['100%'],
        body: [
          [
            {text: 'ประเภทประกัน', style: 'headerTable', border: [false, false, false, false], margin: [0, 0, 0, 0]},
          ],
          [
            {text: insurance_type_name, style: 'headerTable', border: [false, false, false, true], margin: [0, -8, 0, 0]},
          ],
          [
            {text: 'บริษัทประกัน', style: 'headerTable', border: [false, false, false, false], margin: [0, 0, 0, 0]},
          ],
          [
            {text: insurance_company_name, style: 'headerTable', border: [false, false, false, true], margin: [0, -8, 0, 0]},
          ],
          [
            {text: 'เลขที่รับแจ้ง', style: 'headerTable', border: [false, false, false, false], margin: [0, 0, 0, 0]},
          ],
          [
            {text: ' ', style: 'headerTable', border: [false, false, false, true], margin: [0, -8, 0, 0]},
          ],
          [
            {text: 'วันที่รับแจ้ง', style: 'headerTable', border: [false, false, false, false], margin: [0, 0, 0, 0]},
          ],
          [
            {text: ' ', style: 'headerTable', border: [false, false, false, true], margin: [0, -8, 0, 0]},
          ],
        ]
      }
    }

    let tableCarSell05 = {
      table: {
        widths: ['100%'],
        body: [
          [
            {text: 'ชื่อ-นามสกุล', style: 'headerTable', border: [false, false, false, false], margin: [0, 0, 0, 0]},
          ],
          [
            {text: agent_fullname, style: 'headerTable', border: [false, false, false, true], margin: [0, -8, 0, 0]},
          ],
          [
            {text: 'เบอร์โทร', style: 'headerTable', border: [false, false, false, false], margin: [0, 0, 0, 0]},
          ],
          [
            {text: agent_mobile, style: 'headerTable', border: [false, false, false, true], margin: [0, -8, 0, 0]},
          ],
          [
            {text: 'บัญชี', style: 'headerTable', border: [false, false, false, false], margin: [0, 0, 0, 0]},
          ],
          [
            {text: '.', style: 'normalWhite', border: [false, false, false, true], margin: [0, -8, 0, 0]},
          ],
          [
            {text: 'ยอด', style: 'headerTable', border: [false, false, false, false], margin: [0, 0, 0, 0]},
          ],
          [
            {text: car_external_commission, style: 'headerTable', border: [false, false, false, true], margin: [0, -8, 0, 0]},
          ],
        ]
      }
    }

    let tableCarSell =  {
      table: {
        widths: ['17%', '17%', '17%', '20%', '29%'],
        heights: [20, 120],
        body: [
          [
            {text: 'ทะเบียน', style: 'headerTable',},
            {text: 'ไฟแนนท์', style: 'headerTable', colSpan: 2},
              '',
            {text: 'บริษัทประกัน', style: 'headerTable',},
            {text: 'นายหน้า', style: 'headerTable',},
          ],
          [
            tableCarSell01,
            tableCarSell02,
            tableCarSell03,
            tableCarSell04,
            tableCarSell05
          ]
        ]
      },
      margin: [0, 4, 0, 0]
    };

    // decoration: (‘underline’ or ‘lineThrough’ or ‘overline’)
    let tablePayment01 = {
      table: {
        widths: ['100%'],
        body: [
            [
              {text: 'ลูกค้าชำระ', style: 'normalLeftUnderline',  border: [false, false, false, false], margin: [0, 0, 0, 0]}
            ],
            [
              {
                columns: [
                  { width: 54, style: 'normal', text: 'เงินดาวน์',},
                  { width: 90, style: ['normalCenter', 'bottomLine'], text: down_money_pad,},
                  { width: 20, style: 'normal', text: 'บาท',},
                ],
                columnGap: 5,
                lineHeight:0.9,
                border: [false, false, false, false]
              },
            ],
            [
              {
                columns: [
                  { width: 54, style: 'normal', text: 'ค่าประกัน',},
                  { width: 90, style: ['normalCenter', 'bottomLine'], text: insurance_price_pad,},
                  { width: 20, style: 'normal', text: 'บาท',},
                ],
                columnGap: 5,
                lineHeight:0.9,
                border: [false, false, false, false]
              },
            ],
            [
              {
                columns: [
                  { width: 54, style: 'normal', text: 'ค่างวดล่วงหน้า',},
                  { width: 90, style: ['normalCenter', 'bottomLine'], text: pay_advance_finance_pad,},
                  { width: 20, style: 'normal', text: 'บาท',},
                ],
                columnGap: 5,
                lineHeight:0.9,
                border: [false, false, false, false]
              },
            ],
            [
              {
                columns: [
                  { width: 54, style: 'normal', text: 'อื่นๆ',},
                  { width: 90, style: 'normal', text: '___________________',},
                  { width: 20, style: 'normal', text: 'บาท',},
                ],
                columnGap: 5,
                lineHeight:0.9,
                border: [false, false, false, false]
              },
            ],
            [
              {
                columns: [
                  { width: 54, style: 'normalLeftUnderline', text: 'รวม',},
                  { width: 90, style: 'normal', text: '___________________',},
                  { width: 20, style: 'normal', text: 'บาท',},
                ],
                columnGap: 5,
                lineHeight:0.9,
                border: [false, false, false, false]
              },
            ],
            [
              {
                columns: [
                  { width: 54, style: 'normalLeftUnderline', text: 'เงินจอง',},
                  { width: 90, style: ['normalCenter', 'bottomLine'], text: reservation_money_pad,},
                  { width: 20, style: 'normal', text: 'บาท',},
                ],
                columnGap: 5,
                lineHeight:0.9,
                border: [false, false, false, false]
              },
            ],
        ]
      }
    }

    //
    let tablePayment02 = {
      table: {
        widths: ['100%'],
        body: [
          [
            {
              text: 'CAROK ชำระ',
              style: 'normalLeftUnderline',
              border: [false, false, false, false],
              margin: [0, 0, 0, 0]
            }
          ],
          [
            {
              columns: [
                { width: 54, style: 'normal', text: 'โปรโมชั่น',},
                { width: 90, style: 'normal', text: '___________________',},
                { width: 20, style: 'normal', text: 'บาท',},
              ],
              columnGap: 5,
              lineHeight:0.9,
              border: [false, false, false, false]
            },
          ],
          [
            {
              columns: [
                { width: 54, style: 'normal', text: 'เทิร์นรถ',},
                { width: 90, style: 'normal', text: '___________________',},
                { width: 20, style: 'normal', text: 'บาท',},
              ],
              columnGap: 5,
              lineHeight:0.9,
              border: [false, false, false, false]
            },
          ],
          [
            {
              columns: [
                { width: 54, style: 'normal', text: 'จัดเกิน',},
                { width: 90, style: 'normal', text: '___________________',},
                { width: 20, style: 'normal', text: 'บาท',},
              ],
              columnGap: 5,
              lineHeight:0.9,
              border: [false, false, false, false]
            },
          ],
          [
            {
              columns: [
                { width: 54, style: 'normal', text: 'อื่นๆ',},
                { width: 90, style: 'normal', text: '___________________',},
                { width: 20, style: 'normal', text: 'บาท',},
              ],
              columnGap: 5,
              lineHeight:0.9,
              border: [false, false, false, false]
            },
          ],
          [
            {
              columns: [
                { width: 54, style: 'normalLeftUnderline', text: 'รวม',},
                { width: 90, style: 'normal', text: '___________________',},
                { width: 20, style: 'normal', text: 'บาท',},
              ],
              columnGap: 5,
              lineHeight:0.9,
              border: [false, false, false, false]
            },
          ],
          [
            {
              columns: [
                { width: 54, style: 'normalLeftUnderline', text: '*ส่วนต่าง',},
                { width: 90, style: 'normal', text: '___________________',},
                { width: 20, style: 'normal', text: 'บาท',},
              ],
              columnGap: 5,
              lineHeight:0.9,
              border: [false, false, false, false]
            },
          ]
        ]
      }
    }

    //{text: 'ยอดชำระ', style: 'normalLeftUnderline',},
    let tablePayment03 = {
      table: {
        widths: ['100%'],
        body: [
          [
            {
              text: 'ยอดชำระ',
              style: 'normalLeftUnderline',
              border: [false, false, false, false],
              margin: [0, 0, 0, 0]
            }
          ],
          [
            {
              columns: [
                { width: 10, style: 'normal', text: '',},
                {
                  svg: '<svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">' +
                      '  <rect width="10" height="10" x="0" y="0" style="fill:white;stroke:black;stroke-width:1;opacity:0.5" />' +
                      '</svg>'
                },
                { width: 36, style: 'normal', text: 'CAROKชำระ', margin: [-16, -3, 0, 0]},
                { width: 5, style: 'normal', text: '',},
                {
                  svg: '<svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">' +
                      '  <rect width="10" height="10" x="0" y="0" style="fill:white;stroke:black;stroke-width:1;opacity:0.5" />' +
                      '</svg>'
                },
                { width: 36, style: 'normal', text: 'ลูกค้าชำระ', margin: [-16, -3, 0, 0]},
              ],
              columnGap: 5,
              lineHeight:0.9,
              border: [false, false, false, false]
            },
          ],
          [
            {
              columns: [
                { width: 54, style: 'normal', text: 'ยอดชำระ',},
                { width: 90, style: 'normal', text: '___________________',},
                { width: 20, style: 'normal', text: 'บาท',},
              ],
              columnGap: 5,
              lineHeight:0.9,
              border: [false, false, false, false],
              margin: [0, 10, 0, 0]
            },
          ],
          [
            {
              columns: [
                { width: 10, style: 'normal', text: '',},
                {
                  svg: '<svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">' +
                      '  <rect width="10" height="10" x="0" y="0" style="fill:white;stroke:black;stroke-width:1;opacity:0.5" />' +
                      '</svg>'
                },
                { width: 36, style: 'normal', text: 'เงินสด', margin: [-16, -3, 0, 0]},
                { width: 5, style: 'normal', text: '',},
                {
                  svg: '<svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">' +
                      '  <rect width="10" height="10" x="0" y="0" style="fill:white;stroke:black;stroke-width:1;opacity:0.5" />' +
                      '</svg>'
                },
                { width: 36, style: 'normal', text: 'โอน', margin: [-16, -3, 0, 0]},
              ],
              columnGap: 5,
              lineHeight:0.9,
              border: [false, false, false, false],
              margin: [0, 12, 0, 0]
            },
          ],

          [
            {
              columns: [
                { width: 54, style: 'normal', text: 'เลขที่บัญชี',},
                { width: 90, style: 'normal', text: '___________________',},
                { width: 20, style: 'normal', text: '',},
              ],
              columnGap: 5,
              lineHeight:0.9,
              border: [false, false, false, false]
            },
          ],
          [
            {
              columns: [
                { width: 54, style: 'normal', text: 'ค้างชำระ',},
                { width: 90, style: 'normal', text: '___________________',},
                { width: 20, style: 'normal', text: 'บาท',},
              ],
              columnGap: 5,
              lineHeight:0.9,
              border: [false, false, false, false]
            },
          ]
        ]
      }
    }

    // tablePayment02
    // {text: 'CAROK ชำระ', style: 'normalLeftUnderline',},
    let tablePayments = {
      table: {
        widths: ['33%', '34%', '33%'],
        heights: [20, 130],
        body: [
            [
              {text: 'รายละเอียดการชำระเงิน', style: 'headerTable', colSpan: 3},
                '',
                ''
            ],
            [
              tablePayment01,
              tablePayment02,
              tablePayment03
            ]
        ]
      },
      margin: [0, 4, 0, 0]
    }

    let tableCustomerHeader = {
      table: {
        widths: ['100%'],
        heights: [20],
        body: [
          [
            {text: 'รายละเอียดลูกค้า', style: 'headerTable'},
          ],
        ]
      },
      margin: [0, 4, 0, 0]
    }

    let tableCustomer01 = {
      table: {
        widths: ['100%'],
        heights: [18],
        body: [
          [
            {text: 'คนใช้รถ', style: 'normalLeftUnderline', border: [true, true, true, false]},
          ],
          [
            {
              columns: [
                { width: 30, style: 'normal', text: 'ชื่อ',},
                { width: 90, style: 'normal', text: '___________________',},
                { width: 30, style: 'normal', text: 'นามสกุล',},
                { width: 90, style: 'normal', text: '___________________',},
                { width: 48, style: 'normal', text: 'ที่พักปัจจุบัน',},
                { width: 240, style: 'normal', text: '___________________________________________________',},
              ],
              border: [true, false, true, false]
            }
          ],
          [
            {
              columns: [
                { width: 50, style: 'normal', text: 'ชื่อที่ทำงาน',},
                { width: 120, style: 'normal', text: '__________________________',},
                { width: 30, style: 'normal', text: 'ที่อยู่',},
                { width: 180, style: 'normal', text: '______________________________________',},
                { width: 30, style: 'normal', text: 'Tel:',},
                { width: 120, style: 'normal', text: '________________________',},
              ],
              border: [true, false, true, true]
            }
          ],
        ]
      },
      margin: [0, 3, 0, 0]
    };

    let tableCustomer02 = {
      table: {
        widths: ['100%'],
        heights: [18],
        body: [
          [
            {
              columns: [
                {width: 90, text: 'คู่สมรส/บุคคลอ้างอิง', style: 'normalLeftUnderline'},
                { width: 100, style: 'normal', text: 'ความสัมพันธ์กับคนใช้รถ',},
                { width: 90, style: 'normal', text: '___________________',},
              ],
              border: [true, true, true, false]
            }
          ],
          [
            {
              columns: [
                { width: 30, style: 'normal', text: 'ชื่อ',},
                { width: 90, style: 'normal', text: '___________________',},
                { width: 30, style: 'normal', text: 'นามสกุล',},
                { width: 90, style: 'normal', text: '___________________',},
                { width: 48, style: 'normal', text: 'ที่พักปัจจุบัน',},
                { width: 240, style: 'normal', text: '___________________________________________________',},
              ],
              border: [true, false, true, false]
            }
          ],
          [
            {
              columns: [
                { width: 50, style: 'normal', text: 'ชื่อที่ทำงาน',},
                { width: 120, style: 'normal', text: '__________________________',},
                { width: 30, style: 'normal', text: 'ที่อยู่',},
                { width: 180, style: 'normal', text: '______________________________________',},
                { width: 30, style: 'normal', text: 'Tel:',},
                { width: 120, style: 'normal', text: '________________________',},
              ],
              border: [true, false, true, true]
            }
          ],
        ]
      },
      margin: [0, 3, 0, 0]
    };

    let tableCustomer03 = {
      table: {
        widths: ['100%'],
        heights: [18],
        body: [
          [
            {
              columns: [
                {width: 90, text: 'คนค้ำ', style: 'normalLeftUnderline'},
                { width: 100, style: 'normal', text: 'ความสัมพันธ์กับคนใช้รถ',},
                { width: 90, style: 'normal', text: '___________________',},
              ],
              border: [true, true, true, false]
            }
          ],
          [
            {
              columns: [
                { width: 30, style: 'normal', text: 'ชื่อ',},
                { width: 90, style: 'normal', text: '___________________',},
                { width: 30, style: 'normal', text: 'นามสกุล',},
                { width: 90, style: 'normal', text: '___________________',},
                { width: 48, style: 'normal', text: 'ที่พักปัจจุบัน',},
                { width: 240, style: 'normal', text: '___________________________________________________',},
              ],
              border: [true, false, true, false]
            }
          ],
          [
            {
              columns: [
                { width: 50, style: 'normal', text: 'ชื่อที่ทำงาน',},
                { width: 120, style: 'normal', text: '__________________________',},
                { width: 30, style: 'normal', text: 'ที่อยู่',},
                { width: 180, style: 'normal', text: '______________________________________',},
                { width: 30, style: 'normal', text: 'Tel:',},
                { width: 120, style: 'normal', text: '________________________',},
              ],
              border: [true, false, true, true]
            }
          ],
        ]
      },
      margin: [0, 3, 0, 0]
    };

    let tableCustomer04 = {
      table: {
        widths: ['100%'],
        heights: [18],
        body: [
          [
            {
              columns: [
                {width: 90, text: 'คนซื้อแทน', style: 'normalLeftUnderline'},
                { width: 100, style: 'normal', text: 'ความสัมพันธ์กับคนใช้รถ',},
                { width: 90, style: 'normal', text: '___________________',},
              ],
              border: [true, true, true, false]
            }
          ],
          [
            {
              columns: [
                { width: 30, style: 'normal', text: 'ชื่อ',},
                { width: 90, style: 'normal', text: '___________________',},
                { width: 30, style: 'normal', text: 'นามสกุล',},
                { width: 90, style: 'normal', text: '___________________',},
                { width: 48, style: 'normal', text: 'ที่พักปัจจุบัน',},
                { width: 240, style: 'normal', text: '___________________________________________________',},
              ],
              border: [true, false, true, false]
            }
          ],
          [
            {
              columns: [
                { width: 50, style: 'normal', text: 'ชื่อที่ทำงาน',},
                { width: 120, style: 'normal', text: '__________________________',},
                { width: 30, style: 'normal', text: 'ที่อยู่',},
                { width: 180, style: 'normal', text: '______________________________________',},
                { width: 30, style: 'normal', text: 'Tel:',},
                { width: 120, style: 'normal', text: '________________________',},
              ],
              border: [true, false, true, true]
            }
          ],
        ]
      },
      margin: [0, 3, 0, 0]
    };

    /*let blockCustomer = [
      {
        columns: [
          { width: 68, style: 'normal',
            text: 'ชื่อผู้เช่าซื้อ' ,
          },
          { width: '32%', style: ['normalLeft', 'bottomLine'],
            text:  customer_name_pad,
          },
          { width: 100, style: 'normal',
            text: 'เลขบัตรประชาชน' ,
          },
          { width: 'auto', style: ['normalLeft', 'bottomLine'],
            text: customer_card_pad,
          },
        ],
        columnGap: 2,
        lineHeight:1
      },
      {
        columns: [
          { width: 58, style: 'normal',
            text: 'เบอร์โทร' ,
          },
          { width: '32%', style: ['normalLeft', 'bottomLine'],
            text:  customer_mobile_pad,
          },
        ],
        columnGap: 2,
        lineHeight:1,
        margin: [0, 16, 0, 0],
      }
    ]*/

    /*let blockCar = [
      {
        columns: [
          { width: 76, style: 'normal',
            text: 'เช่าซื้อรถยนต์' ,
          },
          { width: '32%', style: ['normalLeft', 'bottomLine'],
            text:  car_name_pad,
          },
          { width: 100, style: 'normal',
            text: 'หมายเลขทะเบียน' ,
          },
          { width: 'auto', style: ['normalLeft', 'bottomLine'],
            text: car_license_pad,
          },
        ],
        columnGap: 2,
        lineHeight:1,
        margin: [0, 16, 0, 0],
      },
      {
        columns: [
          { width: 110, style: 'normal',
            text: 'จัดไฟแนนซ์ธนาคาร' ,
          },
          { width: '32%', style: ['normalLeft', 'bottomLine'],
            text:  bank_finance_name,
          }
        ],
        columnGap: 2,
        lineHeight:1,
        margin: [0, 16, 0, 0],
      },
      {
        columns: [
          { width: '100%', style: 'normal',
            text: 'ได้ตรวจสอบสภาพรถ และรับรถสภาพเรียบร้อยแล้ว' ,
          },
        ],
        columnGap: 2,
        lineHeight:1,
        margin: [0, 16, 0, 0],
      },
    ]*/

    let tableGuarantee = {
      margin: [0, 5, 0, 0],
      layout: 'noBorders', // optional
      table: {
        widths: ['100%'],
        body: [
          [
            {
              columns: [
                { width: 54, style: 'normalBold', text: 'รับประกัน',},
                { width: 110, style: 'normalBold', text: '___________________',},
                { width: 30, style: 'normalBold', text: 'จำนวน',},
                { width: 110, style: 'normalBold', text: '___________________',},
                { width: 20, style: 'normalBold', text: 'เดือน',},
              ]
            }
          ],
        ]
      }
    };

    let tableSign = {
      margin: [0, 5, 0, 0],
      layout: 'noBorders', // optional
      table: {
        widths: ['100%'],
        body: [
          [
            { text: 'ลงชื่อ...............................................................................ผู้ขาย',
              style: 'headerTable',
              lineHeight:1.0 },
          ],
        ]
      }
    };

    /*let blockImage = []
    if (cardIdImage64 !== null && carDeliverImage64 !== null) {
      blockImage = [
        {
          columns: [
            {
              width: 260,
              //text: 'image'
              image: cardIdImage64
            },
            {
              width: 260,
              //text: 'image'
              image: carDeliverImage64
            },
          ],
          columnGap: 10,
          lineHeight: 1.3,
          margin: [ 0, 30, 0, 0 ], // margin: [left, top, right, bottom]
        },
      ]
    }*/


    // blockCar,
    // tableSign,
    // blockImage

    const docDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'A4',
      pageMargins: [10, 10, 20, 6],
      /*header: function (currentPage, pageCount, pageSize) {
        // you can apply any logic and return any valid pdfmake element
        return [
          {
            margin: [30, 30, 30, 0],
            layout: 'noBorders', // optional
            table: {
              widths: ['100%'],
              body: [
                [
                  {
                    layout: 'noBorders',
                    table: {
                      widths: ['80%', '20%'],
                      body: [
                        [
                          { text: 'Printed Date : ' + printDate },
                          { text: 'Page ' + currentPage + ' of ' + pageCount, style: 'right' }
                        ]
                      ]
                    }
                  }
                ]
              ]
            }
          }
        ]
      },*/
      content: [
        { text: 'ใบส่งมอบรถ', style: 'header', margin: [0, 0, 0, 6], },
        tableTent,
        tableFinance,
        tableCarSell,
        tablePayments,
        tableCustomerHeader,
        tableCustomer01,
        tableCustomer02,
        tableCustomer03,
        tableCustomer04,
        tableGuarantee,
        tableSign
      ],
      defaultStyle: {
        font: 'THSarabunNew',
        fontSize: 15,
      },

      styles: {
        header: {
          alignment: 'center',
          bold: true,
          fontSize: 16
        },
        headerTable: {
          alignment: 'center',
          bold: true,
          fontSize: 13
        },
        normal: {
          fontSize: 13,
        },
        normalRight: {
          fontSize: 13,
          alignment: 'right'
        },
        normalLeft: {
          fontSize: 13,
          alignment: 'left',
        },
        normalLeftUnderline:{
          fontSize: 13,
          alignment: 'left',
          decoration: 'underline'
        },
        normalCenter: {
          fontSize: 13,
          alignment: 'center'
        },
        normalBoldCenter: {
          fontSize: 13,
          bold: true,
          alignment: 'center',
        },
        normalBold: {
          fontSize: 13,
          bold: true,
        },
        normalWhite: {
          fontSize: 13,
          color : '#ffffff',
        },
        left22: {
          marginLeft: 22
        },
        topic: {
          bold: true,
          fontSize: 16
        },
        noBorder: {
          bold: true,
          alignment: 'right'
        },
        lineVertical: {
          marginTop: -5
        },
        textStatus: {
          marginTop: -5,
          color: 'red'
        },
        headTable: {
          fontSize: 12,
          padding: '0 10 0 10'
        },
        table: {
          fontSize: 12
        },
        underline: {
          decoration: 'underline'
        },
        bottomLine: {
          decoration: 'underline',
          decorationColor: '#bfbfbf'
          // decorationStyle: 'dashed',
        }
      }
    };

    pdfMake.createPdf(docDefinition).open()
  }

  render () {
    return <></>
  }

}

export default DeliverCarOkPDF
